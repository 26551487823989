import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import "../css/landingPage.css";
import cst from "../images/partnerLogos/cst.png";
import kenli from "../images/partnerLogos/kenli.png";
import kmc from "../images/partnerLogos/kmc.png";
import maxxis from "../images/partnerLogos/maxxis.png";
import wtb from "../images/partnerLogos/wtb.png";
import schwalbe from "../images/partnerLogos/schwalbe.png";
import neco from "../images/partnerLogos/neco.png";
import ralson from "../images/partnerLogos/ralson.png";
import prowheel from "../images/partnerLogos/prowheel.png";
import sunrace from "../images/partnerLogos/sunrace.png";
import shimano from "../images/partnerLogos/shimano.png";
import sram from "../images/partnerLogos/sram.png";
import rockshox from "../images/partnerLogos/rockshox.png";
import microshift from "../images/partnerLogos/microshift.png";
import axios from "axios";
import PhoneInput from "react-phone-number-input";
import Meta from "../components/Meta";
import Modal from "../components/Modal";
import ModalMobile from "../components/ModalMobile";
import ReactPlayer from "react-player";
import CustomBike from "../components/CustomBike";
import "../css/navbar.css";
import logo from "../images/brandLogos/Dynem red white.png";
import { Icon } from "@iconify/react";

const GetQuoteSpain = () => {
  const featuresData = [
    {
      key: "precisionControllers",
      heading: "Controladores de precisión",
      description:
        "Los controladores de una bicicleta eléctrica emplean sensores y algoritmos para ajustar la entrega de potencia sin problemas y su sistema inteligente mejora el rendimiento y hace que la bicicleta eléctrica sea más intuitiva y fácil de usar.",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/Controlerrrr.png",
    },
    {
      key: "highPerformanceChargers",
      heading: "Cargadores de alto rendimiento",
      description:
        "Los cargadores vienen en una variedad de opciones personalizables que se adaptan a las preferencias, según la compatibilidad global, la portabilidad o la flexibilidad energética extendida.",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/Chargerrrr.png",
    },
    {
      key: "multifunctionalDisplays",
      heading: "Pantallas multifuncionales",
      description:
        "Estas pantallas LCD ofrecen datos en tiempo real, como la velocidad y la batería, lo que permite a los ciclistas monitorear y ajustar sus modos de conducción.",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/Displayyyyy.png",
    },
    {
      key: "longLastingBatteries",
      heading: "Baterías de larga duración",
      description:
        "Este sistema eléctrico almacena energía y alimenta el motor eléctrico. Esta tecnología ha sido diseñada para garantizar un rendimiento constante, una mayor duración de la batería y una entrega de potencia óptima, mejorando la experiencia general de conducción.",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/Baterryyyyy.png",
    },
    {
      key: "efficientMotors",
      heading: "Motores eficientes",
      description:
        "Nuestros motores versátiles e innovadores han sido diseñados para ofrecer un rendimiento confiable y preciso, garantizando una utilidad óptima en diversas aplicaciones.",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/motorrrr.png",
    },
  ];
  const [activeFeature1, setActiveFeature1] = useState("precisionControllers");
  const handleFeature1Click = (feature) => {
    setActiveFeature1(feature.key);
  };
  const getActiveFeature1 = () => {
    return featuresData.find((feature) => feature.key === activeFeature1);
  };
  const activeFeatureObj1 = getActiveFeature1();
  const [open, openNav] = useState(false);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, []);

  const closeNavforDeadLink = () => {
    openNav(false);
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [designation, setDesignation] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("");
  const [enquiry, setEnquiry] = useState("");

  const componentData = [
    {
      key: "Frame",
    },
    {
      key: "Motor",
    },
    {
      key: "Derailleur",
    },
    {
      key: "Battery",
    },
    {
      key: "Display",
    },
  ];
  const [activeComponent, setActiveComponent] = useState("Frame");
  const handleComponentClick = (feature) => {
    setActiveComponent(feature.key);
  };

  const navigate = useNavigate();

  const submit = async (e) => {
    e.preventDefault();
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const reqBody = {
      name,
      email,
      phoneNumber,
      companyName,
      designation,
      country,
      enquiry,
    };
    const { data } = await axios.post(
      "/api/form/request-a-quote",
      reqBody,
      config
    );
    if (data) {
      navigate("/details-submitted");
    }
  };
  return (
    <>
      {/* landing page main banner */}
      <Meta
        title={"Get a Quote - Get Wholesale E-bike Purchase Quote from Dynem"}
        description={
          "Get a wholesale e-bike purchase quote from Dynem, a leading e-bike manufacturer and supplier. Request your personalized quote today for bulk orders."
        }
        cannonicalTag={"https://www.dynem.in/get-quote/es"}
      />
      {/* desktop navbar */}
      <div
        className="position-sticky w-100 navbar"
        style={{ zIndex: "99", top: "0%" }}
      >
        <div className="row container mx-auto px-3 px-lg-0">
          <div className="col-12 col-md-4 d-flex justify-content-start p-0">
            <Link to={"/"}>
              <img src={logo} alt="dynem-logo" className="logo"></img>
            </Link>
          </div>
          <div className="col-5 d-flex nav-items p-0"></div>
          <div className="col-3 p-0 d-none d-lg-block d-md-block">
            <div className="d-flex justify-content-end align-items-center">
              <Modal
                language={"spanish"}
                className="primary-button fw-bold px-4 py-2"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="get-quote-landing">
        {/* hero-banner */}
        <div className="hero-banner position-relative">
          <picture className="main-banner-img">
            <source
              media="(max-width:650px)"
              srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/banners/heroBanner_mobile.jpg"
            />
            <source
              media="(min-width:651px)"
              srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/banners/heroBanner.jpg"
            />
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/banners/heroBanner.jpg"
              alt="hero-banner"
              className="d-block w-100"
            />
          </picture>
          <div
            className="d-none d-lg-block position-absolute"
            style={{ top: "10%", left: "7%" }}
          >
            <h1
              className="poppins-bold text-light"
              style={{ fontSize: "3.5em" }}
            >
              <span style={{ color: "#ff0000" }}>IMPULSA TU</span>
              <br></br>
              PRODUCCIÓN DE BICICLETAS ELECTRÓNICAS
            </h1>
            <p className="text-light w-50">
              Eleve su producción de bicicletas eléctricas con la ingeniería de
              precisión y la mano de obra experta de Dynem, brindando resultados
              superiores que distinguen a su marca.
            </p>
          </div>
          <div className="d-lg-none position-absolute" style={{ top: "0%" }}>
            <div className="d-flex justify-content-center w-100 p-4">
              <h1
                className="poppins-bold text-light"
                style={{ fontSize: "2.5em" }}
              >
                <span style={{ color: "#ff0000" }}>IMPULSA TU</span>
                <br></br>
                PRODUCCIÓN DE BICICLETAS ELECTRÓNICAS
              </h1>
            </div>
          </div>
          <div
            className="d-lg-block d-none position-absolute"
            style={{
              bottom: "10%",
              left: "7%",
              backgroundColor: "rgba(151, 151, 151, 0.56)",
            }}
          >
            <div
              className="d-flex p-3 px-5 text-center poppins-medium"
              style={{
                gap: "2em",
              }}
            >
              <h5 className="text-light">
                5,00,000+<br></br>Capacidad de bicicleta eléctrica
              </h5>
              <h5 className="text-light">
                4,00,000+<br></br>Capacidad de componentes
              </h5>
              <h5 className="text-light">
                Tren de<br></br>propulsión interno
              </h5>
              <Modal
                language={"spanish"}
                className="primary-button fw-bold px-4 py-2"
              />
            </div>
          </div>
          <div
            className="d-lg-none position-absolute w-100"
            style={{
              bottom: "10%",
              backgroundColor: "rgba(151, 151, 151, 0.56)",
            }}
          >
            <div
              className="d-flex p-3 text-center poppins-medium"
              style={{
                gap: "1.7em",
                fontSize: "0.8em",
              }}
            >
              <h5 className="text-light">
                5,00,000+<br></br>Capacidad de bicicleta eléctrica
              </h5>
              <h5 className="text-light">
                4,00,000+<br></br>Capacidad de componentes
              </h5>
              <h5 className="text-light">
                Tren de<br></br>propulsión interno
              </h5>
            </div>
          </div>
          <div className="d-lg-none get-quote-banner-button-mb position-absolute">
            <div className="d-flex" style={{ gap: "1.5em" }}>
              <ModalMobile
                language={"spanish"}
                className="primary-button fw-bold px-4 py-2"
              />
            </div>
          </div>
        </div>
        {/* world class products */}
        <div className="">
          <div className=" mt-5 py-4 py-lg-5">
            <h2 className="poppins-medium mb-3 mb-lg-5 text-center container">
              ¡Fabricación de clase, desde la India, para el mundo!
            </h2>
            <div className="news py-5">
              <Splide
                className="mb-2 mb-lg-0 p-0 align-items-center container"
                options={{
                  arrows: false,
                  drag: false,
                  type: "slide",
                  pagination: false,
                  autoplay: false,
                  perMove: 1,
                  gap: "2em",
                  padding: "0em",
                  pauseOnHover: true,
                  resetProgress: false,
                  perPage: 4,
                  speed: 1000,
                  interval: 3000,
                  breakpoints: {
                    700: {
                      drag: true,
                      perPage: 1.3,
                      arrows: false,
                      perMove: 1,
                      gap: "1em",
                      padding: "0em",
                      pauseOnHover: false,
                      resetProgress: false,
                      focus: 0,
                      start: 0,
                      speed: 1000,
                      interval: 3000,
                    },
                  },
                }}
                muted={true}
              >
                <SplideSlide className="h-100">
                  <div className="p-3" style={{ backgroundColor: "white" }}>
                    <div>
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/products/ecycle.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <h5 className="poppins-medium mt-3 text-center">
                      bicicletas eléctricas
                    </h5>
                  </div>
                </SplideSlide>
                <SplideSlide className="h-100">
                  <div className="p-3" style={{ backgroundColor: "white" }}>
                    <div>
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/products/motor.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <h5 className="poppins-medium mt-3 text-center">Motor</h5>
                  </div>
                </SplideSlide>
                <SplideSlide className="h-100">
                  <div className="p-3" style={{ backgroundColor: "white" }}>
                    <div>
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/products/battery.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <h5 className="poppins-medium mt-3 text-center">Batería</h5>
                  </div>
                </SplideSlide>
                <SplideSlide className="h-100">
                  <div className="p-3" style={{ backgroundColor: "white" }}>
                    <div>
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/products/dispaly.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <h5 className="poppins-medium mt-3 text-center">Mostrar</h5>
                  </div>
                </SplideSlide>
              </Splide>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center py-4">
            <Modal
              language={"spanish"}
              className="primary-button fw-bold px-4 py-2 d-lg-block d-none"
            />
            <ModalMobile
              language={"spanish"}
              className="primary-button fw-bold px-4 py-2 d-lg-none"
            />
          </div>
        </div>
        {/* company logos */}
        <div className="container my-5 py-lg-5 d-none d-lg-block">
          <Splide
            className="flex align-items-center"
            extensions={{ AutoScroll }}
            options={{
              type: "loop",
              autoScroll: {
                speed: 1,
                pauseOnHover: false,
              },
              perPage: 6,
              drag: "free",
              arrows: false,
              pagination: false,
              pauseOnHover: false,
              gap: "3em",
              padding: "1em",
              breakpoints: {
                640: {
                  perPage: 3,
                  gap: "1em",
                },
              },
            }}
          >
            <SplideSlide className="h-100">
              <div>
                <img src={shimano} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={kmc} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={maxxis} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={wtb} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={rockshox} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={neco} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={ralson} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={sunrace} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={microshift} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={sram} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={schwalbe} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
          </Splide>
        </div>
        <div className="my-5 py-lg-5 d-lg-none">
          <Splide
            className="flex align-items-center"
            extensions={{ AutoScroll }}
            options={{
              type: "loop",
              autoScroll: {
                speed: 1,
                pauseOnHover: false,
              },
              perPage: 6,
              drag: "free",
              arrows: false,
              pagination: false,
              pauseOnHover: false,
              gap: "3em",
              padding: "1em",
              breakpoints: {
                640: {
                  perPage: 3,
                  gap: "1em",
                },
              },
            }}
          >
            <SplideSlide className="h-100">
              <div>
                <img src={shimano} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={kmc} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={maxxis} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={wtb} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={rockshox} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={neco} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={ralson} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={sunrace} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={microshift} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={sram} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={schwalbe} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
          </Splide>
        </div>
        {/* why choose us */}
        <div className="news">
          <div className="container mt-5 pt-lg-5 pt-5">
            <h2 className="poppins-medium mb-2">Su socio en el éxito</h2>
            <h4 className="">He aquí por qué nos destacamos</h4>
            <div className="py-5">
              <div className="d-flex flex-wrap justify-content-between text-center w-100 mb-3 mb-lg-5 why-choose-us">
                <div className="flex-item">
                  <div className="d-flex justify-content-center mb-3">
                    <img
                      className="img-fluid"
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/production.png"
                    ></img>
                  </div>

                  <p className="m-0 poppins-medium">
                    500,000<br></br>Capacidad de producción
                  </p>
                </div>
                <div className="flex-item">
                  <div className="d-flex justify-content-center mb-3">
                    <img
                      className="img-fluid"
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/component.png"
                    ></img>
                  </div>
                  <p className="m-0 poppins-medium">
                    400,000&nbsp;Capacidad de componentes
                  </p>
                </div>
                <div className="flex-item">
                  <div className="d-flex justify-content-center mb-3">
                    <img
                      className="img-fluid"
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/cost.png"
                    ></img>
                  </div>
                  <p className="m-0 poppins-medium">
                    87% <br></br>Beneficios de costos
                  </p>
                </div>
                <div className="flex-item">
                  <div className="d-flex justify-content-center mb-3">
                    <img
                      className="img-fluid"
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/iot.png"
                    ></img>
                  </div>
                  <p className="m-0 poppins-medium">
                    IOT habilitado<br></br>Soluciones
                  </p>
                </div>
              </div>
              <div className="d-flex flex-wrap flex-wrap-2 justify-content-between text-center w-100 why-choose-us">
                <div className="flex-item">
                  <div className="d-flex justify-content-center mb-3">
                    <img
                      className="img-fluid"
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/certificate.png"
                    ></img>
                  </div>

                  <p className="mt-2 poppins-medium">
                    Europa<br></br>Certificaciones
                  </p>
                </div>
                <div className="flex-item">
                  <div className="d-flex justify-content-center mb-3">
                    <img
                      className="img-fluid"
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/in-stock.png"
                    ></img>
                  </div>
                  <p className="mt-2 poppins-medium">
                    Stock listo<br></br>disponible
                  </p>
                </div>
                <div className="flex-item">
                  <div className="d-flex justify-content-center mb-3">
                    <img
                      className="img-fluid"
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/automation.png"
                    ></img>
                  </div>
                  <p className="mt-2 poppins-medium">
                    Todos los componentes<br></br>producción interna
                  </p>
                </div>
                <div className="flex-item">
                  <div className="d-flex justify-content-center mb-3">
                    <img
                      className="img-fluid"
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/container.png"
                    ></img>
                  </div>
                  <p className="mt-2 poppins-medium">
                    Antidumping legal<br></br>Solución
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* range of dts */}
        <div className="container mt-5 pt-4 pt-lg-5">
          <h2 className="poppins-medium mb-4 mb-lg-5">
            Componentes que impulsan cada milla
          </h2>
          <div className="d-lg-none pt-4">
            <Splide
              className="mb-5 p-0 align-items-center"
              options={{
                drag: true,
                perPage: 1.2,
                arrows: false,
                pagination: false,
                autoplay: false,
                perMove: 1,
                interval: 4000,
                gap: "1em",
                padding: "0em",
                pauseOnHover: false,
                resetProgress: false,
                focus: 0,
                start: 0,
                speed: 500,
              }}
            >
              {featuresData.slice(0, 4).map((feature) => (
                <SplideSlide className="feature-btn-width">
                  <button
                    key={feature}
                    className={
                      feature.key === activeFeature1
                        ? "em-btn-1 rounded-0"
                        : "em-btn-2 rounded-0"
                    }
                    onClick={() => handleFeature1Click(feature)}
                  >
                    {feature.heading}
                  </button>
                </SplideSlide>
              ))}
            </Splide>
          </div>
          <div className="mb-5 d-none d-lg-block">
            <div
              className="d-flex justify-content-center mb-3"
              style={{ gap: "1em" }}
            >
              {featuresData.map((feature) => (
                <button
                  key={feature}
                  className={
                    feature.key === activeFeature1
                      ? "em-btn-1 rounded-0"
                      : "em-btn-2 rounded-0"
                  }
                  onClick={() => handleFeature1Click(feature)}
                >
                  {feature.heading}
                </button>
              ))}
            </div>
          </div>
          <div className="d-none d-lg-block my-5 px-5">
            <div className="d-flex align-items-center justify-content-center">
              <div className="feature-text w-100">
                <h1>{activeFeatureObj1.heading}</h1>{" "}
                <p>{activeFeatureObj1.description}</p>
              </div>
              <div className="w-100">
                <img
                  src={activeFeatureObj1.image}
                  alt="amigo-feature"
                  className="img-fluid"
                ></img>
              </div>
            </div>
          </div>
          <div className="row mt-5 d-lg-none">
            <div className="col-12 col-lg-6 feature-text">
              <h1>{activeFeatureObj1.heading}</h1>{" "}
              <p>{activeFeatureObj1.description}</p>
            </div>
            <div className="col-12 col-lg-6">
              <div className="d-flex d-lg-block justify-content-center">
                <img
                  src={activeFeatureObj1.image}
                  alt="amigo-feature"
                  className="img-fluid mx-auto"
                ></img>
              </div>
            </div>
          </div>
        </div>
        {/* range of e-bikes */}
        <div className="mt-5 pt-lg-5 ">
          <div className="position-relative">
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/banners/breaker2.jpg"
              className="img-fluid"
            ></img>
            <div
              className="position-absolute d-lg-block d-none"
              style={{ top: "25%", left: "58%" }}
            >
              <div>
                <h1
                  className="poppins-medium text-light"
                  style={{ fontSize: "4em" }}
                >
                  Explore nuestra<br></br>flota eléctrica
                </h1>
              </div>
            </div>
          </div>
          <h2 className="poppins-medium d-lg-none my-4 text-center">
            Explore nuestra flota eléctrica
          </h2>
          <div className=" py-3 d-lg-none">
            <Splide
              className="mb-2 mb-lg-0 p-0 align-items-center container"
              options={{
                arrows: false,
                drag: false,
                type: "slide",
                pagination: false,
                autoplay: false,
                perMove: 1,
                gap: "2em",
                padding: "0em",
                pauseOnHover: true,
                resetProgress: false,
                perPage: 4,
                speed: 1000,
                interval: 3000,
                breakpoints: {
                  700: {
                    drag: true,
                    perPage: 1.3,
                    arrows: false,
                    perMove: 1,
                    gap: "1em",
                    padding: "0em",
                    pauseOnHover: false,
                    resetProgress: false,
                    focus: 0,
                    start: 0,
                    speed: 1000,
                    interval: 3000,
                  },
                },
              }}
              muted={true}
            >
              <SplideSlide className="h-100">
                <div className="p-3 news">
                  <div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ebikes/mountain.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="mt-3 text-center" style={{ height: "40px" }}>
                    Bicicletas eléctricas de montaña
                  </h4>
                  <div style={{ height: "160px" }}>
                    <p className="text-center m-0">
                      No somos sólo un fabricante de bicicletas eléctricas de
                      montaña; Somos pioneros en aventuras todoterreno. Explore
                      una gama de bicicletas eléctricas meticulosamente
                      diseñadas para terrenos accidentados y paseos
                      emocionantes.
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-3 news">
                  <div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ebikes/fat-tyre.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="mt-3 text-center" style={{ height: "40px" }}>
                    Bicicletas eléctricas plegables y con neumáticos anchos
                  </h4>
                  <div style={{ height: "160px" }}>
                    <p className="text-center m-0">
                      Como fabricante de bicicletas eléctricas plegables de
                      primer nivel, DYNEM le ofrece la mejor combinación de
                      comodidad y estilo. Nuestras bicicletas eléctricas
                      plegables son perfectas para exploradores urbanos y
                      miembros de la jet-set.
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-3 news">
                  <div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ebikes/city.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="mt-3 text-center" style={{ height: "40px" }}>
                    Bicicletas eléctricas urbanas
                  </h4>
                  <div style={{ height: "160px" }}>
                    <p className="text-center m-0">
                      Como fabricante líder de bicicletas urbanas, DYNEM
                      redefine los desplazamientos urbanos con vehículos
                      elegantes, eficientes y con estilo. Nuestras bicicletas
                      urbanas combinan tecnología de vanguardia con un diseño
                      moderno, lo que garantiza que te deslizarás por el tráfico
                      con facilidad.
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-3 news">
                  <div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ebikes/cargo.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>

                  <h4 className="mt-3 text-center" style={{ height: "40px" }}>
                    Entrega de bicicletas eléctricas
                  </h4>
                  <div style={{ height: "160px" }}>
                    <p className="text-center m-0">
                      Como fabricante líder de bicicletas de reparto, DYNEM
                      ofrece rendimiento y confiabilidad de primer nivel en cada
                      entrega. Nuestras bicicletas eléctricas están diseñadas
                      para las exigencias de la logística urbana, motores
                      potentes y amplio espacio de carga.
                    </p>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
          <div className="range-ebikes container d-lg-block d-none py-5 my-5">
            <Splide
              className="mb-2 mb-lg-0 p-0 align-items-center text-center"
              options={{
                arrows: true,
                start: 0,
                drag: false,
                type: "loop",
                pagination: true,
                autoplay: true,
                gap: "3em",
                padding: "1em",
                pauseOnHover: true,
                resetProgress: false,
                perPage: 1,
                speed: 1000,
                interval: 4000,
                breakpoints: {
                  700: {
                    drag: true,
                    autoplay: false,
                    pagination: "false",
                    perPage: 1,
                    perMove: 1,
                    gap: "2em",
                    padding: "0em",
                    arrows: false,
                    pauseOnHover: false,
                    resetProgress: false,
                  },
                },
              }}
              muted={true}
            >
              <SplideSlide className="h-100">
                <div className="p-4 pt-0 p-lg-0 d-flex align-items-center ecycle-range-card">
                  <div className="">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ebikes/mountain.png"
                      alt=""
                      className="w-100"
                    />
                  </div>
                  <div className="text-start p-3">
                    <h4 className="poppins-medium">
                      Bicicletas eléctricas de montaña
                    </h4>
                    <p className="">
                      No somos sólo un fabricante de bicicletas eléctricas de
                      montaña; Somos pioneros en aventuras todoterreno. Explore
                      una gama de bicicletas eléctricas meticulosamente
                      diseñadas para terrenos accidentados y paseos
                      emocionantes.
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pt-0 p-lg-0 d-flex align-items-center ecycle-range-card">
                  <div className="">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ebikes/fat-tyre.png"
                      alt=""
                      className="w-100"
                    />
                  </div>
                  <div className="text-start p-3">
                    <h4 className="poppins-medium">
                      Bicicletas eléctricas plegables y con neumáticos anchos
                    </h4>
                    <p className="">
                      Como fabricante de bicicletas eléctricas plegables de
                      primer nivel, DYNEM le ofrece la mejor combinación de
                      comodidad y estilo. Nuestras bicicletas eléctricas
                      plegables son perfectas para exploradores urbanos y
                      miembros de la jet-set.
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pt-0 p-lg-0 d-flex align-items-center ecycle-range-card">
                  <div className="">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ebikes/city.png"
                      alt=""
                      className="w-100"
                    />
                  </div>

                  <div className="text-start p-3">
                    <h4 className="poppins-medium">
                      Bicicletas eléctricas urbanas
                    </h4>
                    <p className="">
                      Como fabricante líder de bicicletas urbanas, DYNEM
                      redefine los desplazamientos urbanos con vehículos
                      elegantes, eficientes y con estilo. Nuestras bicicletas
                      urbanas combinan tecnología de vanguardia con un diseño
                      moderno, lo que garantiza que te deslizarás por el tráfico
                      con facilidad
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pt-0 p-lg-0 d-flex align-items-center ecycle-range-card">
                  <div className="">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ebikes/cargo.png"
                      alt=""
                      className="w-100"
                    />
                  </div>

                  <div className="text-start p-3">
                    <h4 className="poppins-medium">
                      Entrega de bicicletas eléctricas
                    </h4>
                    <p className="">
                      Como fabricante líder de bicicletas de reparto, DYNEM
                      ofrece rendimiento y confiabilidad de primer nivel en cada
                      entrega. Nuestras bicicletas eléctricas están diseñadas
                      para las exigencias de la logística urbana, motores
                      potentes y amplio espacio de carga.
                    </p>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </div>
        {/* certifications */}
        <div className="news mt-5 pt-lg-5 pt-4 mb-5">
          <h2 className="poppins-medium mb-3 mb-lg-4 container">
            Certificaciones
          </h2>
          <div className="container pb-4">
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/certifications__png.png"
              alt="certifications"
              className="w-100"
            ></img>
          </div>
        </div>
        <div className="container mt-5 pt-lg-5">
          <h2 className="poppins-medium mb-2">Haz el cambio</h2>
          <h4 className="mb-3 mb-lg-5">
            Simplifique su producción con nosotros
          </h4>
          <div className="mx-lg-5 switching">
            <Splide
              className="mb-lg-0 p-0 align-items-center"
              options={{
                arrows: true,
                start: 0,
                drag: false,
                type: "loop",
                pagination: true,
                autoplay: true,
                gap: "3em",
                padding: "0em",
                pauseOnHover: true,
                resetProgress: false,
                perPage: 4,
                perMove: 1,
                speed: 1000,
                interval: 4000,
                breakpoints: {
                  700: {
                    drag: true,
                    arrows: false,
                    autoplay: false,
                    perPage: 1,
                    perMove: 1,
                    gap: "0em",
                    padding: "0em",
                    pauseOnHover: false,
                    resetProgress: false,
                  },
                },
              }}
              muted={true}
            >
              <SplideSlide className="h-100 ">
                <div className="p-4 pb-0 pt-0 p-lg-0 mb-4">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/1.png"
                      alt=""
                      className="img-fluid col-6 col-md-10"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">Producción en masa</h5>
                    <p className="m-0">
                      Alineación en la finalización del pedido posterior a la
                      producción.
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pb-0 pt-0 p-lg-0 mb-4">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/2.png"
                      alt=""
                      className="img-fluid col-6 col-md-10"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">Proceso de muestreo</h5>
                    <p className="m-0">
                      También permite verificar la validación de tarifas
                      antidumping.
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pb-0 pt-0 p-lg-0 mb-4">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/3.png"
                      alt=""
                      className="img-fluid col-6 col-md-10"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">
                      Explora nuestra oferta de productos
                    </h5>
                    <p className="m-0">
                      Igualaremos sus especificaciones y le ofreceremos una
                      oferta localizada completa (país de origen)
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pb-0 pt-0 p-lg-0 mb-4">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/4.png"
                      alt=""
                      className="img-fluid col-6 col-md-10"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">
                      Organizar la visita a la fábrica
                    </h5>
                    <p className="m-0">
                      Para brindarle información detallada sobre la maquinaria y
                      el proceso de fabricación de última generación.
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pb-0 pt-0 p-lg-0 mb-4">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/5.png"
                      alt=""
                      className="img-fluid col-6 col-md-10"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">
                      Finalización del producto
                    </h5>
                    <p className="m-0">
                      Pruebas posteriores al muestreo y por lotes
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pb-0 pt-0 p-lg-0 mb-4">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/6.png"
                      alt=""
                      className="img-fluid col-6 col-md-10"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">Soporte en Postventa</h5>
                    <p className="m-0">
                      Junto con y repuestos planificación - Europa y EE.UU.
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pb-0 pt-0 p-lg-0 mb-4">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/7.png"
                      alt=""
                      className="img-fluid col-6 col-md-10"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">
                      Ampliar el soporte logístico
                    </h5>
                    <p className="m-0">
                      Para habilitar la mejor solución de envío con nuestra
                      amplia red de socios
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pb-0 pt-0 p-lg-0 mb-4">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/8.png"
                      alt=""
                      className="img-fluid col-6 col-md-10"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">Envía tus requisitos</h5>
                    <p className="m-0">
                      (imágenes y especificaciones de la bicicleta)
                    </p>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center mt-5">
          <Modal
            language={"spanish"}
            className="primary-button fw-bold px-4 py-2 d-lg-block d-none"
          />
          <ModalMobile
            language={"spanish"}
            className="primary-button fw-bold px-4 py-2 d-lg-none"
          />{" "}
        </div>
        {/* amigo */}
        <div className="news my-5 py-5">
          <h2 className="poppins-medium mb-5 container">
            Amigo - Una aplicación | Posibilidades ilimitadas
          </h2>
          <div
            className="d-flex container pt-3"
            style={{ overflowX: "auto", gap: "2em" }}
          >
            <div className="mb-4 amigo-spec">
              <div className="position-relative">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/amigo/fitness.png"
                  className="w-100"
                ></img>
                <div
                  className="position-absolute"
                  style={{ top: "17%", right: "13%" }}
                >
                  <div className="d-flex align-items-end">
                    <p className="fw-bold">Modo de fitness</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4 amigo-spec">
              <div className="position-relative">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/amigo/diagnostic.png"
                  className="w-100"
                ></img>
                <div
                  className="position-absolute"
                  style={{ top: "13%", right: "0%" }}
                >
                  <div className="d-flex align-items-end">
                    <p className="fw-bold">
                      Diagnóstico<br></br>completo de bicicletas
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4 amigo-spec">
              <div className="position-relative">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/amigo/sos.png"
                  className="w-100"
                ></img>
                <div
                  className="position-absolute"
                  style={{ top: "13%", right: "15%" }}
                >
                  <div className="d-flex align-items-end">
                    <p className="fw-bold">
                      Alerta SOS<br></br>de emergencia
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center mt-4 mt-lg-5">
          <Modal
            language={"spanish"}
            className="primary-button fw-bold px-4 py-2 d-lg-block d-none"
          />
          <ModalMobile
            language={"spanish"}
            className="primary-button fw-bold px-4 py-2 d-lg-none"
          />{" "}
        </div>
        {/* testimonials */}
        <div className="mt-5 py-lg-5 container testimonials">
          <h2 className="poppins-medium mb-3 mb-lg-5 container">Testimonios</h2>
          <div className="mt-5 mt-lg-0">
            <Splide
              className="mb-lg-0 p-0 align-items-center"
              options={{
                arrows: true,
                start: 0,
                drag: false,
                type: "loop",
                pagination: true,
                autoplay: true,
                gap: "1.5em",
                padding: "0em",
                pauseOnHover: true,
                resetProgress: false,
                perPage: 1,
                perMove: 1,
                speed: 1000,
                interval: 4000,
                breakpoints: {
                  700: {
                    drag: true,
                    arrows: false,
                    autoplay: false,
                    perPage: 1,
                    perMove: 1,
                    gap: "1em",
                    padding: "0em",
                    pauseOnHover: false,
                    resetProgress: false,
                  },
                },
              }}
              muted={true}
            >
              <SplideSlide className="h-100">
                <div className="d-flex p-lg-4" style={{ gap: "2em" }}>
                  <div className="w-25 d-none d-lg-block">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/testimonial1.png"
                      className="rounded-0"
                    ></img>
                  </div>
                  <div className="position relative">
                    <div className="position-absolute inverted-comma d-lg-none">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/inverted.png"
                        className="img-fluid"
                      ></img>
                    </div>
                    <div className="ps-5 ps-lg-0">
                      <h4 className="poppins-medium text-uppercase mb-4">
                        “¡Uno de los mejores fabricantes de bicicletas
                        eléctricas del mercado! Constantemente he personalizado
                        bicicletas eléctricas a mi gusto y nunca decepcionan,
                        ¡recomendado 11/10! ............”
                      </h4>
                      <p className="poppins-medium mb-0">James Mill</p>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="d-flex p-lg-4" style={{ gap: "2em" }}>
                  <div className="w-25 d-none d-lg-block">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/testimonial2.png"
                      className="rounded-0"
                    ></img>
                  </div>
                  <div className="position relative">
                    <div className="position-absolute inverted-comma d-lg-none">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/inverted.png"
                        className="img-fluid"
                      ></img>
                    </div>
                    <div className="ps-5 ps-lg-0">
                      <h4 className="poppins-medium text-uppercase mb-4">
                        “¡Cambiarnos a Dynem fue un cambio radical para
                        nosotros! Todo el proceso, desde el abastecimiento hasta
                        la entrega, fue perfecto. Además, los ahorros que
                        logramos al trasladar nuestra fabricación a la India
                        fueron sustanciales. ¡Muy recomendable!"
                      </h4>
                      <p className="poppins-medium mb-0">Jake Severisson</p>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="d-flex p-lg-4" style={{ gap: "2em" }}>
                  <div className="w-25 d-none d-lg-block">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/testimonial3.png"
                      className="rounded-0"
                    ></img>
                  </div>
                  <div className="position relative">
                    <div className="position-absolute inverted-comma d-lg-none">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/inverted.png"
                        className="img-fluid"
                      ></img>
                    </div>
                    <div className="ps-5 ps-lg-0">
                      <h4 className="poppins-medium text-uppercase mb-4">
                        "Trabajar con Dynem ha sido excelente. La transición de
                        China a la India fue increíblemente fluida y los ahorros
                        de costos que obtuvimos fueron significativos. ¡Un gran
                        socio para la fabricación de bicicletas eléctricas!"
                      </h4>
                      <p className="poppins-medium mb-0">Carolina Spike</p>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="d-flex p-lg-4" style={{ gap: "2em" }}>
                  <div className="w-25 d-none d-lg-block">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/testimonial4.png"
                      className="rounded-0"
                    ></img>
                  </div>
                  <div className="position relative">
                    <div className="position-absolute inverted-comma d-lg-none">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/inverted.png"
                        className="img-fluid"
                      ></img>
                    </div>
                    <div className="ps-5 ps-lg-0">
                      <h4 className="poppins-medium text-uppercase mb-4">
                        "Dynem hizo que nuestro cambio a la fabricación india
                        fuera sin esfuerzo. Su atención al detalle y su
                        compromiso con la calidad fueron impresionantes, y los
                        beneficios de costos fueron inmediatos. ¡Estamos muy
                        satisfechos con el cambio!”
                      </h4>
                      <p className="poppins-medium mb-0">Kevin Spearson</p>
                    </div>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </div>
        {/* news */}
        <div className="news">
          <div className="container mt-5 py-4 py-lg-5">
            <h2 className="poppins-medium mb-3 mb-lg-5">En las noticias</h2>
            <Splide
              className="mb-2 mb-lg-0 p-0 align-items-center"
              options={{
                arrows: false,
                drag: false,
                type: "slide",
                pagination: false,
                autoplay: false,
                perMove: 1,
                gap: "2em",
                padding: "0em",
                pauseOnHover: true,
                resetProgress: false,
                perPage: 4,
                speed: 1000,
                interval: 3000,
                breakpoints: {
                  700: {
                    drag: true,
                    perPage: 1.3,
                    arrows: false,
                    perMove: 1,
                    gap: "1em",
                    padding: "0em",
                    pauseOnHover: false,
                    resetProgress: false,
                    focus: 0,
                    start: 0,
                    speed: 1000,
                    interval: 3000,
                  },
                },
              }}
              muted={true}
            >
              <SplideSlide className="h-100">
                <div className="p-3" style={{ backgroundColor: "white" }}>
                  <div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/News+1.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/kxan.png"
                    className="img-fluid mt-2"
                  ></img>
                  <p className="my-3 news-heading poppins-medium">
                    DYNEM inicia la construcción de la Gigafábrica de Ciclo
                    Eléctrico Integrado más grande del mundo.....
                  </p>
                  <small>May 09, 2024</small>
                  <div
                    className="w-100 text-center py-2 mt-2"
                    style={{
                      backgroundColor: "black",
                      minHeight: "max-content",
                    }}
                  >
                    <a
                      className="text-light text-decoration-none"
                      href="https://www.kxan.com/business/press-releases/cision/20240509IO08933/dynem-begins-construction-of-worlds-biggest-integrated-electric-cycle-gigafactory-in-india/"
                    >
                      Leer más
                    </a>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-3" style={{ backgroundColor: "white" }}>
                  <div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/News+2.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ktla.png"
                    className="img-fluid mt-2"
                  ></img>
                  <p className="my-3 news-heading poppins-medium">
                    DYNEM de la India establecerá la gigafábrica integrada de
                    bicicletas eléctricas más grande del mundo
                  </p>
                  <small>May 09, 2024</small>
                  <div
                    className="w-100 text-center py-2 mt-2"
                    style={{
                      backgroundColor: "black",
                      minHeight: "max-content",
                    }}
                  >
                    <a
                      className="text-light text-decoration-none"
                      href="https://fox59.com/business/press-releases/cision/20240509IO08933/dynem-begins-construction-of-worlds-biggest-integrated-electric-cycle-gigafactory-in-india/"
                    >
                      Leer más
                    </a>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-3" style={{ backgroundColor: "white" }}>
                  <div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/News+3.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/benzinga.png"
                    className="img-fluid mt-2"
                  ></img>
                  <p className="my-3 news-heading poppins-medium">
                    DYNEM inicia la construcción de la gigafábrica de ciclos
                    eléctricos integrados más grande del mundo...
                  </p>
                  <small>May 09, 2024</small>
                  <div
                    className="w-100 text-center py-2 mt-2"
                    style={{
                      backgroundColor: "black",
                      minHeight: "max-content",
                    }}
                  >
                    <a
                      className="text-light text-decoration-none"
                      href="https://www.benzinga.com/pressreleases/24/05/n38730936/dynem-begins-construction-of-worlds-biggest-integrated-electric-cycle-gigafactory-in-india"
                    >
                      Leer más
                    </a>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-3" style={{ backgroundColor: "white" }}>
                  <div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/News+4.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/fox8.png"
                    className="img-fluid mt-2"
                  ></img>
                  <p className="my-3 news-heading poppins-medium">
                    DYNEM fortalece la destreza manufacturera de la India: la
                    más grande del mundo....
                  </p>
                  <small>May 09, 2024</small>
                  <div
                    className="w-100 text-center py-2 mt-2"
                    style={{
                      backgroundColor: "black",
                      minHeight: "max-content",
                    }}
                  >
                    <a
                      className="text-light text-decoration-none"
                      href="https://www.benzinga.com/pressreleases/24/05/n38730936/dynem-begins-construction-of-worlds-biggest-integrated-electric-cycle-gigafactory-in-india"
                    >
                      Leer más
                    </a>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </div>
        {/* FAQs */}
        <div className="container mt-5 pt-lg-5">
          <div className="d-flex faq-landing">
            <div className="faq-landing-1">
              <h2 className="poppins-medium mb-2 mb-lg-4">
                Preguntas frecuentes
              </h2>
              <p className="mb-lg-5">
                ¿Tienes preguntas? Ya tenemos respuestas. Es como si pudiéramos
                oírte pensar.
              </p>
              <div className="d-lg-block d-none">
                <h4 className="poppins-medium mb-2">Did we miss something?</h4>
                <p className="mb-lg-5">
                  Realice una llamada individual con nuestros expertos.
                </p>
                <div className="req-call my-5 my-lg-0">
                  <a
                    href="tel:+918956703515"
                    className="btn btn-dark px-5 d-flex justify-content-between rounded-0"
                    style={{ width: "fit-content" }}
                  >
                    Solicitar una llamada
                  </a>
                </div>
              </div>
            </div>
            <div className="accordion faq-landing-2" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    ¿Qué diferencia a DYNEM de otros fabricantes de bicicletas
                    eléctricas?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body poppins-thin">
                    <p>
                      DYNEM se destaca por su innovación de vanguardia,
                      prácticas ecológicas y amplias opciones de
                      personalización. Nuestra gigafábrica de última generación,
                      cumplimiento global y equipo de expertos garantizan una
                      calidad de primer nivel y un soporte perfecto desde la
                      producción hasta el servicio posventa, lo que le deja nada
                      más que tranquilidad.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    ¿Puedo personalizar mi bicicleta eléctrica con DYNEM?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      ¡Absolutamente! DYNEM ofrece amplias opciones de
                      personalización, lo que le permite adaptar su bicicleta
                      eléctrica a su estilo y necesidades específicas. Nuestro
                      servicio OEM le permite personalizar la marca, las
                      especificaciones y las características para que coincidan
                      con sus preferencias.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    ¿Cuántas bicicletas eléctricas produce DYNEM al año?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      DYNEM tiene capacidad para producir y personalizar 500.000
                      bicicletas eléctricas al año. Nuestros procesos de
                      producción eficientes y nuestra infraestructura de clase
                      mundial nos permiten satisfacer la creciente demanda
                      global.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFour">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    ¿Cuáles son los beneficios de las soluciones antidumping de
                    DYNEM?
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      Las soluciones legales antidumping de DYNEM permiten a los
                      importadores ahorrar hasta un 87% en impuestos. Nuestros
                      productos cumplen con las regulaciones globales, lo que
                      garantiza igualdad de condiciones sin impuestos
                      antidumping. Esto convierte a DYNEM en una opción rentable
                      y que cumple con las normas para los importadores de todo
                      el mundo.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingfive">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    ¿Cómo apoya DYNEM la sostenibilidad?
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      DYNEM está comprometido con la sostenibilidad a través de
                      prácticas ecológicas y tecnología innovadora. Nuestras
                      bicicletas eléctricas están diseñadas para reducir la
                      huella de carbono y promover un futuro más ecológico,
                      alineándose con nuestra visión de movilidad sostenible.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingSix">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    ¿Qué tipo de soporte postventa ofrece DYNEM?
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingSix"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      DYNEM prioriza la satisfacción del cliente con un soporte
                      posventa dedicado. Desde la compra inicial hasta el
                      mantenimiento a largo plazo, nuestro equipo se compromete
                      a brindar asistencia confiable y fluida, garantizando una
                      experiencia fluida y satisfactoria para nuestros clientes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-lg-none text-center mt-3">
              <h4 className="poppins-medium mb-2">¿Nos perdimos algo?</h4>
              <p className="mb-lg-5">
                Realice una llamada individual con nuestros expertos.
              </p>
              <div className="req-call my-4">
                <a
                  href="tel:+918956703515"
                  className="btn btn-dark px-5 d-flex justify-content-between rounded-0"
                  style={{ width: "fit-content" }}
                >
                  Solicitar una llamada
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-block d-none">
          <div className="d-flex justify-content-center align-items-center mt-4 mt-lg-5">
            <Modal
              language={"spanish"}
              className="primary-button fw-bold px-4 py-2 d-lg-block d-none"
            />
          </div>
        </div>

        {/* contact us */}
        <div className="mt-5 py-lg-5" style={{ backgroundColor: "black" }}>
          <div className="container py-lg-5 py-4">
            <div className="d-flex mb-3 mb-lg-0" style={{ gap: "4em" }}>
              <div className="d-none d-lg-block w-50">
                <div className="row">
                  <div className="col-6 p-0">
                    <div className="d-flex justify-content-end">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid1.png"
                        className="w-75 p-3"
                      ></img>
                    </div>
                    <div className="d-flex justify-content-end">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid5.png"
                        className="w-100 p-3"
                      ></img>
                    </div>
                  </div>
                  <div className="col-6 p-0">
                    <div className="d-flex justify-content-start mt-5">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid2.png"
                        className="w-100 p-3"
                      ></img>
                    </div>
                    <div className="d-flex justify-content-start">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid4.png"
                        className="w-75 p-3"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-none d-lg-block">
                <h2 className="poppins-medium mb-4 text-light">Conectemos</h2>
                <form className="contact-us-landing" onSubmit={submit}>
                  <div class="mb-3 d-flex" style={{ gap: "1em" }}>
                    <div className="w-50">
                      <label
                        for="exampleInputEmail1"
                        className="form-label text-light"
                      >
                        Nombre completo*
                      </label>
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        required
                        class="form-control rounded-0"
                        aria-describedby="emailHelp"
                      />
                    </div>
                    <div className="w-50">
                      <label
                        for="exampleInputEmail1"
                        className="form-label text-light"
                      >
                        País*
                      </label>
                      <input
                        type="text"
                        value={country}
                        onChange={(e) => {
                          setCountry(e.target.value);
                        }}
                        required
                        class="form-control rounded-0"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>
                  <div class="mb-3 d-flex" style={{ gap: "1em" }}>
                    <div class="w-50">
                      <label
                        for="exampleInputPassword1"
                        class="form-label text-light"
                      >
                        Número de contacto*
                      </label>
                      <PhoneInput
                        value={phoneNumber}
                        onChange={setPhoneNumber}
                        required
                        defaultCountry="IN"
                        className="form-control w-100 rounded-0"
                      />
                    </div>
                    <div className="w-50">
                      <label
                        for="exampleInputEmail1"
                        className="form-label text-light"
                      >
                        Correo electrónico del trabajo*
                      </label>
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        required
                        class="form-control rounded-0"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>

                  <div class="mb-4 d-flex" style={{ gap: "1em" }}>
                    <div className="w-50">
                      <label
                        for="exampleInputEmail1"
                        className="form-label text-light"
                      >
                        Organización*
                      </label>
                      <input
                        type="text"
                        value={companyName}
                        required
                        onChange={(e) => {
                          setCompanyName(e.target.value);
                        }}
                        class="form-control rounded-0"
                        aria-describedby="emailHelp"
                      />
                    </div>
                    <div className="w-50">
                      <label
                        for="exampleInputEmail1"
                        className="form-label text-light"
                      >
                        Designación*
                      </label>
                      <input
                        type="text"
                        required
                        value={designation}
                        onChange={(e) => {
                          setDesignation(e.target.value);
                        }}
                        class="form-control rounded-0"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label
                      for="exampleInputEmail1"
                      className="form-label text-light"
                    >
                      Publique su consulta*
                    </label>
                    <textarea
                      class="form-control rounded-0"
                      required
                      id="exampleFormControlTextarea1"
                      rows="3"
                      value={enquiry}
                      onChange={(e) => {
                        setEnquiry(e.target.value);
                      }}
                    ></textarea>
                  </div>
                  <button
                    type="submit"
                    class="btn btn-light w-100 poppins-medium rounded-0"
                  >
                    Obtener cotización
                  </button>
                </form>
              </div>
            </div>
            <div className="d-lg-none mb-5">
              <h2 className="poppins-medium mb-4 text-light">Conectemos</h2>
              <form className="contact-us-landing" onSubmit={submit}>
                <div class="mb-3 d-flex" style={{ gap: "1em" }}>
                  <div className="w-50">
                    <label
                      for="exampleInputEmail1"
                      className="form-label text-light"
                    >
                      Nombre completo*
                    </label>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      required
                      class="form-control rounded-0"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div className="w-50">
                    <label
                      for="exampleInputEmail1"
                      className="form-label text-light"
                    >
                      País*
                    </label>
                    <input
                      type="text"
                      value={country}
                      onChange={(e) => {
                        setCountry(e.target.value);
                      }}
                      required
                      class="form-control rounded-0"
                      aria-describedby="emailHelp"
                    />
                  </div>
                </div>
                <div class="mb-3 d-flex" style={{ gap: "1em" }}>
                  <div class="w-50">
                    <label
                      for="exampleInputPassword1"
                      class="form-label text-light"
                    >
                      Número de contacto*
                    </label>
                    <PhoneInput
                      value={phoneNumber}
                      onChange={setPhoneNumber}
                      required
                      defaultCountry="IN"
                      className="form-control w-100 rounded-0"
                    />
                  </div>
                  <div className="w-50">
                    <label
                      for="exampleInputEmail1"
                      className="form-label text-light"
                    >
                      Correo electrónico del trabajo*
                    </label>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      required
                      class="form-control rounded-0"
                      aria-describedby="emailHelp"
                    />
                  </div>
                </div>

                <div class="mb-4 d-flex" style={{ gap: "1em" }}>
                  <div className="w-50">
                    <label
                      for="exampleInputEmail1"
                      className="form-label text-light"
                    >
                      Organización*
                    </label>
                    <input
                      type="text"
                      value={companyName}
                      required
                      onChange={(e) => {
                        setCompanyName(e.target.value);
                      }}
                      class="form-control rounded-0"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div className="w-50">
                    <label
                      for="exampleInputEmail1"
                      className="form-label text-light"
                    >
                      Designación*
                    </label>
                    <input
                      type="text"
                      required
                      value={designation}
                      onChange={(e) => {
                        setDesignation(e.target.value);
                      }}
                      class="form-control rounded-0"
                      aria-describedby="emailHelp"
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label
                    for="exampleInputEmail1"
                    className="form-label text-light"
                  >
                    Publique su consulta*
                  </label>
                  <textarea
                    class="form-control rounded-0"
                    id="exampleFormControlTextarea1"
                    required
                    rows="3"
                    value={enquiry}
                    onChange={(e) => {
                      setEnquiry(e.target.value);
                    }}
                  ></textarea>
                </div>
                <button
                  type="submit"
                  class="btn btn-light w-100 poppins-medium rounded-0"
                >
                  Obtener cotización
                </button>
              </form>
            </div>
            <div className="row p-2 d-lg-none">
              <div className="col-6 p-0">
                <div className="d-flex justify-content-end">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid1.png"
                    className="w-75 p-2"
                  ></img>
                </div>
                <div className="d-flex justify-content-end">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid5.png"
                    className="w-100 p-2"
                  ></img>
                </div>
              </div>
              <div className="col-6 p-0">
                <div className="d-flex justify-content-start mt-5">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid2.png"
                    className="w-100 p-2"
                  ></img>
                </div>
                <div className="d-flex justify-content-start">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid4.png"
                    className="w-75 p-2"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetQuoteSpain;
