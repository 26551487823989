import React from "react";
import logo from "../images/brandLogos/Dynem red white.png";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import "../css/footer.css";

const Footer = () => {
  return (
    <>
      <footer className="pt-4 pe-5 pe-3 ps-3 pe-lg-5 ps-lg-5 d-none d-lg-block">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <img src={logo} className="logo" alt="dynem-logo" />
              <p className="mt-lg-3 footer-desc text-light">
                DYNEM is the powerhouse that has set the gold standard for
                manufacturing ebikes and drivetrain solutions across the globe.
                We are re-shaping the world of electric mobility with our
                innovative technology and commitment to a sustainable future.
              </p>
              <p className="footer-desc">
                For General Enquires:{" "}
                <a href="tel:+918956703515" className="footer-links text-light">
                  +91 8956703515
                </a>{" "}
                <span className="text-light">| </span>
                <a
                  href="mailto:contactus@dynem.in"
                  className="footer-links text-light"
                >
                  contactus@dynem.in
                </a>{" "}
              </p>
              <div className="d-flex mt-3 footer-desc" style={{ gap: "1em" }}>
                <div>Address:</div>
                <div>
                  <a
                    href="https://www.google.com/maps?cid=17998967583726053340"
                    className="footer-links text-light"
                  >
                    At post Jambe, Taluka Mulshi, 159/2 Sangawade, Road, Pune,
                    Maharashtra 411033
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 footer-links p-4 ps-5 pe-5">
              <div className="row">
                <div className="col-6">
                  <h5 className="color-dark mb-3 mt-3">E-Bikes</h5>
                  <Link to="/e-bikes/mountain" className="">
                    Mountain Bikes
                  </Link>
                  <Link to="/e-bikes/folding-fat-tyre" className="">
                    Folding/Fat-Tyre Bikes
                  </Link>
                  <Link to="/e-bikes/city" className="">
                    City Bikes
                  </Link>
                  <Link to="/e-bikes/delivery" className="">
                    Delivery Bikes
                  </Link>
                </div>
                {/* <div className="col-4">
                  <h5 className="color-dark mb-3 mt-3">Drive Train Systems</h5>{" "}
                  <Link to="/drive-train-systems" className="txt-regular">
                    Motor Controller
                  </Link>
                  <Link to="/drive-train-systems" className="txt-regular">
                    Battery
                  </Link>
                  <Link to="/drive-train-systems" className="txt-regular">
                    Motor
                  </Link>
                  <Link to="/drive-train-systems" className="txt-regular">
                    Display
                  </Link>
                  <Link to="/drive-train-systems" className="txt-regular">
                    Derailleur
                  </Link>
                </div> */}

                <div className="col-6">
                  <h5 className="color-dark mb-3 mt-3 ">Menu</h5>{" "}
                  <Link to="/" className="txt-regular">
                    Home
                  </Link>
                  <Link to="/e-bikes" className="txt-regular">
                    E-bikes
                  </Link>
                  <Link to="/components" className="txt-regular">
                    Drive Train Systems
                  </Link>
                  <Link to="/tech" className="txt-regular">
                    Tech
                  </Link>
                  <Link to="/about-us" className="txt-regular">
                    About Us
                  </Link>
                  <Link to="/contact-us" className="txt-regular">
                    Contact Us
                  </Link>
                  <Link to="/blogs" className="txt-regular">
                    Blogs
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-12 mt-3">
              <div className=" ">
                <div
                  className="d-flex justify-content-around justify-content-md-start  mt-3  "
                  style={{ gap: "3em" }}
                >
                  <div className="">
                    <a
                      href="https://www.facebook.com/dynemindia"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      {" "}
                      <Icon
                        icon="ic:baseline-facebook"
                        width="1.5em"
                        height="1.5em"
                        style={{ color: "#ffffff" }}
                      />
                    </a>
                  </div>
                  <div className="">
                    <a
                      href="https://twitter.com/e_motorad"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      {" "}
                      <Icon
                        icon="fa6-brands:x-twitter"
                        width="1.5em"
                        height="1.5em"
                        style={{ color: "#ffffff" }}
                      />
                    </a>
                  </div>
                  <div className="">
                    <a
                      href="https://www.instagram.com/dynemindia/"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <Icon
                        icon="mdi:instagram"
                        width="1.5em"
                        height="1.5em"
                        style={{ color: "#ffffff" }}
                      />
                    </a>
                  </div>
                  <div className="">
                    <a
                      href="https://www.linkedin.com/company/emotorad/"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      {" "}
                      <Icon
                        icon="mdi:linkedin"
                        width="1.5em"
                        height="1.5em"
                        style={{ color: "#ffffff" }}
                      />
                    </a>
                  </div>
                  <div className="">
                    <a
                      href="https://www.youtube.com/channel/UCBaDv3uoPiBPSVDZJHGCCWg"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      {" "}
                      <Icon
                        icon="bi:youtube"
                        width="1.5em"
                        height="1.5em"
                        style={{ color: "#ffffff" }}
                      />
                    </a>
                  </div>
                </div>
                <div className="row footer-bottom mt-4">
                  <div className="col-10">
                    <p className="">
                      Copyright © INKODOP TECHNOLOGIES PRIVATE LIMITED 2023 All
                      rights reserved.
                    </p>
                  </div>
                  <div className="col-2 ps-5 pe-5">
                    <div className="d-md-flex d-none" style={{ gap: "1.5em" }}>
                      <Link to="/privacy-policy" className="txt-regular">
                        Privacy Policy
                      </Link>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <footer className="pt-4 pe-3 ps-3 pe-lg-5 ps-lg-5  d-lg-none">
        <div className="">
          <div className="row">
            <div className="col-lg-6 mb-3">
              <img
                src={logo}
                style={{ width: "150px" }}
                alt=""
                className="mb-3"
              />
              <p className=" mt-lg-3   footer-desc text-light">
                DYNEM is the powerhouse that has set the gold standard for
                manufacturing ebikes and drivetrain solutions across the globe.
                We are re-shaping the world of electric mobility with our
                innovative technology and commitment to a sustainable future.
              </p>
              <p className=" footer-desc">
                For General Enquires:{" "}
                <a href="tel:+918956703515" className="footer-links text-light">
                  +91 8956703515
                </a>{" "}
                <span className="text-light">| </span>
                <a
                  href="mailto:contactus@dynem.in"
                  className="footer-links text-light"
                >
                  contactus@dynem.in
                </a>{" "}
              </p>
              <div className="d-flex mt-3 footer-desc " style={{ gap: "1em" }}>
                <div>Address:</div>
                <div>
                  <a
                    href="https://www.google.com/maps?cid=17998967583726053340"
                    className="footer-links text-light"
                  >
                    At post Jambe, Taluka Mulshi, 159/2 Sangawade, Road, Pune,
                    Maharashtra 411033
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-6 footer-links">
              <div
                className="d-flex justify-content-between align-items-center "
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample2"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                <h5 className="mt-3 dropdown-footer-heading">E-Bikes</h5>
                <Icon
                  icon="ep:arrow-down"
                  width="1.3em"
                  height="1.3em"
                  style={{ color: "#ff0000" }}
                />
              </div>
              <div
                className="collapse rounded-3 border-r-5 dropdown-footer-options"
                id="collapseExample2"
              >
                <Link to="/e-bikes/mountain" className="">
                  Mountain Bikes
                </Link>
                <Link to="/e-bikes/folding-fat-tyre" className="">
                  Folding/Fat-Tyre Bikes
                </Link>
                <Link to="/e-bikes/city" className="">
                  City Bikes
                </Link>
                <Link to="/e-bikes/delivery" className="">
                  Delivery Bikes
                </Link>
              </div>
              <hr className="mb-1" />
            </div>

            {/* <div className="col-lg-6 footer-links">
              <div
                className="d-flex justify-content-between align-items-center"
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample1"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                <h5 className="mt-3 dropdown-footer-heading">
                  Drive Train Systems
                </h5>
                <Icon
                  icon="ep:arrow-down"
                  width="1.3em"
                  height="1.3em"
                  style={{ color: "#ff0000" }}
                />
              </div>
              <div
                className="collapse rounded-3 border-r-5 dropdown-footer-options"
                id="collapseExample1"
              >
                <Link to="/drive-train-systems" className="txt-regular">
                  Motor Controller
                </Link>
                <Link to="/drive-train-systems" className="txt-regular">
                  Battery
                </Link>
                <Link to="/drive-train-systems" className="txt-regular">
                  Motor
                </Link>
                <Link to="/drive-train-systems" className="txt-regular">
                  Display
                </Link>
                <Link to="/drive-train-systems" className="txt-regular">
                  Derailleur
                </Link>
              </div>
              <hr className="mb-1" />
            </div> */}

            <div className="col-lg-6 footer-links">
              <div
                className="d-flex justify-content-between align-items-center "
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample3"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                <h5 className="mt-3 dropdown-footer-heading">Menu</h5>
                <Icon
                  icon="ep:arrow-down"
                  width="1.3em"
                  height="1.3em"
                  style={{ color: "#ff0000" }}
                />
              </div>
              <div
                className="collapse rounded-3 border-r-5 dropdown-footer-options"
                id="collapseExample3"
              >
                <Link to="/" className="txt-regular">
                  Home
                </Link>
                <Link to="/e-bikes" className="txt-regular">
                  E-bikes
                </Link>
                <Link to="/components" className="txt-regular">
                  Drive Train Systems
                </Link>
                <Link to="/tech" className="txt-regular">
                  Tech
                </Link>
                <Link to="/about-us" className="txt-regular">
                  About Us
                </Link>
                <Link to="/contact-us" className="txt-regular">
                  Contact Us
                </Link>
                <Link to="/blogs" className="txt-regular">
                  Blogs
                </Link>
              </div>
              <hr className="mb-1" />
            </div>

            <div className="col-lg-6 footer-links">
              <div
                className="d-flex justify-content-between align-items-center "
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample4"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                <h5 className="mt-3 dropdown-footer-heading">Legal</h5>
                <Icon
                  icon="ep:arrow-down"
                  width="1.3em"
                  height="1.3em"
                  style={{ color: "#ff0000" }}
                />
              </div>
              <div
                className="collapse rounded-3 border-r-5 dropdown-footer-options"
                id="collapseExample4"
              >
                <Link to="/privacy-policy" className="txt-regular">
                  Privacy Policy
                </Link>{" "}
              </div>
              <hr className="mb-1" />
            </div>
            <div className="col-lg-12 mt-3">
              <div className=" ">
                <div
                  className="d-flex justify-content-around justify-content-md-start  mt-3  "
                  style={{ gap: "3em" }}
                >
                  <div className="">
                    <a
                      href="https://www.facebook.com/dynemindia"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      {" "}
                      <i className="fa-brands  social fa-facebook-f"></i>
                    </a>
                  </div>
                  <div className="">
                    <a
                      href="https://twitter.com/e_motorad"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      {" "}
                      <i className="fa-brands social fa-twitter"></i>
                    </a>
                  </div>
                  <div className="">
                    <a
                      href="https://www.instagram.com/dynemindia/"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <i className="fa-brands social fa-instagram"></i>
                    </a>
                  </div>
                  <div className="">
                    <a
                      href="https://www.linkedin.com/company/emotorad/"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      {" "}
                      <i className="fa-brands social fa-linkedin-in"></i>
                    </a>
                  </div>
                  <div className="">
                    <a
                      href="https://www.youtube.com/channel/UCBaDv3uoPiBPSVDZJHGCCWg"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      {" "}
                      <i className="fa-brands social fa-youtube"></i>
                    </a>
                  </div>
                </div>

                <div className="footer-bottom mt-4">
                  <div className="text-center">
                    <p className="">
                      Copyright © INKODOP TECHNOLOGIES PRIVATE LIMITED 2023 All
                      rights reserved.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
