import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../css/modal.css";
import PhoneInput from "react-phone-number-input";

const Modal = (props) => {
  const { className } = props;
  const { customBikeData } = props;
  const { language } = props;
  const translations = {
    french: {
      requestQuote: "Demander un devis",
      name: "Nom et prénom*",
      number: "Numéro de contact*",
      email: "E-mail professionnel*",
      organisation: "Organisation*",
      country: "Pays*",
      designation: "Désignation*",
      enquiry: "Publiez votre demande*",
      getQuote: "Obtenir un devis*",
    },
    italy: {
      requestQuote: "Richiedi un preventivo",
      name: "Nome e cognome*",
      number: "Contatta il n*",
      email: "E-mail di lavoro*",
      organisation: "Organizzazione*",
      country: "Paese*",
      designation: "Designazione*",
      enquiry: "Pubblica la tua richiesta*",
      getQuote: "Ottieni un preventivo*",
    },
    spanish: {
      requestQuote: "Solicitar una cotización*",
      name: "Nombre completo*",
      number: "Número de contacto*",
      email: "Correo electrónico del trabajo*",
      organisation: "Organización*",
      country: "País*",
      designation: "Designación*",
      enquiry: "Publique su consulta*",
      getQuote: "Obtener cotización*",
    },
    dutch: {
      requestQuote: "Vraag een offerte aan",
      name: "Volledige naam*",
      number: "Contactnr*",
      email: "Werk e-mail*",
      organisation: "Organisatie*",
      country: "Land*",
      designation: "Aanduiding*",
      enquiry: "Plaats uw aanvraag*",
      getQuote: "Ontvang een offerte*",
    },
    german: {
      requestQuote: "Fordern Sie ein Angebot an",
      name: "Vollständiger Name*",
      number: "Kontakt Nr*",
      email: "Arbeits-E-Mail*",
      organisation: "Organisation*",
      country: "Land*",
      designation: "Bezeichnung*",
      enquiry: "Veröffentlichen Sie Ihre Anfrage*",
      getQuote: "Holen Sie sich ein Angebot*",
    },
  };
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [designation, setDesignation] = useState("");
  const [country, setCountry] = useState("");
  const [enquiry, setEnquiry] = useState("");

  const navigate = useNavigate();

  const submit = async (e) => {
    e.preventDefault();
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const reqBody = {
      name,
      email,
      phoneNumber,
      companyName,
      designation,
      customBikeData,
      country,
      enquiry,
    };
    const { data } = await axios.post(
      "/api/form/request-a-quote",
      reqBody,
      config
    );
    if (data) {
      navigate("/details-submitted");
    }
  };
  return (
    <>
      <button
        type="button"
        className={`fw-bold ${className}`}
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        {language ? translations[language].requestQuote : "Request a Quote"}
      </button>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div
            class="modal-content p-3 pt-2 rounded-4"
            style={{ backgroundColor: "#F9F9FF" }}
          >
            <div class="modal-header pt-2 mb-3">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body p-3">
              <form className="" onSubmit={submit}>
                <div class="mb-3 d-flex" style={{ gap: "1em" }}>
                  <div className="w-50">
                    <label for="exampleInputEmail1" className="form-label ">
                      {language ? translations[language].name : "Full Name*"}
                    </label>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      required
                      class="form-control"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div className="w-50">
                    <label for="exampleInputEmail1" className="form-label ">
                      {language ? translations[language].country : "Country*"}
                    </label>
                    <input
                      type="text"
                      value={country}
                      onChange={(e) => {
                        setCountry(e.target.value);
                      }}
                      required
                      class="form-control"
                      aria-describedby="emailHelp"
                    />
                  </div>
                </div>
                <div class="mb-3 d-flex" style={{ gap: "1em" }}>
                  <div class="w-50">
                    <label for="exampleInputPassword1" class="form-label ">
                      {language ? translations[language].number : "Contact No*"}
                    </label>
                    <PhoneInput
                      value={phoneNumber}
                      onChange={setPhoneNumber}
                      required
                      defaultCountry="IN"
                      className="form-control w-100"
                    />
                  </div>
                  <div className="w-50">
                    <label for="exampleInputEmail1" className="form-label ">
                      {language ? translations[language].email : "Work Email*"}
                    </label>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      required
                      class="form-control"
                      aria-describedby="emailHelp"
                    />
                  </div>
                </div>

                <div class="mb-4 d-flex" style={{ gap: "1em" }}>
                  <div className="w-50">
                    <label for="exampleInputEmail1" className="form-label ">
                      {language
                        ? translations[language].organisation
                        : "Organisation*"}
                    </label>
                    <input
                      type="text"
                      value={companyName}
                      required
                      onChange={(e) => {
                        setCompanyName(e.target.value);
                      }}
                      class="form-control"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div className="w-50">
                    <label for="exampleInputEmail1" className="form-label ">
                      {language
                        ? translations[language].designation
                        : "Designation*"}
                    </label>
                    <input
                      type="text"
                      value={designation}
                      required
                      onChange={(e) => {
                        setDesignation(e.target.value);
                      }}
                      class="form-control"
                      aria-describedby="emailHelp"
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label for="exampleInputEmail1" className="form-label ">
                    {language
                      ? translations[language].enquiry
                      : "Post Your Enquiry*"}
                  </label>
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    required
                    value={enquiry}
                    onChange={(e) => {
                      setEnquiry(e.target.value);
                    }}
                  ></textarea>
                </div>
                <button
                  type="submit"
                  class="btn primary-button w-100 poppins-medium"
                  data-bs-dismiss="modal"
                >
                  {language ? translations[language].getQuote : "Get Quote"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
