import React from "react";
import { Link } from "react-router-dom";

const Button = (props) => {
  const { label, to, className } = props;

  return (
    <Link to={to} className={`text-decoration-none ${className}`}>
      {label}
    </Link>
  );
};

export default Button;
