import React from "react";

const DisplaySelection = ({ components, selected, onSelectComponent }) => {
  const handleSelect = (display) => {
    onSelectComponent(display);
  };
  return (
    <div className="pt-4">
      <div className="parameter-list px-4 mb-3">
        {components.map((display) => (
          <div
            key={display.id}
            className={`text-center mb-4 component-card ${
              selected && selected.id === display.id ? "selected" : ""
            }`}
            onClick={() => handleSelect(display)}
          >
            <img
              src={display.image}
              alt={display.name}
              className="img-fluid component-image"
            />
            <p
              className={`m-0 mt-3 fw-bold component-text ${
                selected && selected.id === display.id ? "selected-text" : ""
              }`}
            >
              {display.name}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DisplaySelection;
