import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Meta from "../components/Meta";

const AntiDumping = () => {
  return (
    <>
      <Meta
        title={
          "Legal Anti Dumping Solution, Import E-bikes to EU Without Anti Dumping Duties"
        }
        description={
          "Discover how to import e-bikes to the EU without anti-dumping duties. Learn about Dynem's legal anti-dumping solution for European buyers."
        }
        cannonicalTag={"https://www.dynem.in/legal-anti-dumping-solution"}
      />
      <Navbar />
      {/* hero banner */}
      <div className="mb-1 mb-lg-2">
        <picture className="">
          <source
            media="(max-width:650px)"
            srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/desktop/banners/Home+Banner+2+desktop.jpg"
          />
          <source
            media="(min-width:651px)"
            srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/desktop/banners/Home+Banner+2+desktop.jpg"
          />
          <img
            src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/desktop/banners/Home+Banner+2+desktop.jpg"
            alt="assurance"
            className="d-block img-fluid"
          />
        </picture>
      </div>
      {/* hero banner */}
      <div className="mb-1 mb-lg-2">
        <picture className="">
          <source
            media="(max-width:650px)"
            srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/antiDumpingPage/banners/antidumping2.jpg"
          />
          <source
            media="(min-width:651px)"
            srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/antiDumpingPage/banners/antidumping2.jpg"
          />
          <img
            src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/antiDumpingPage/banners/antidumping2.jpg"
            alt="assurance"
            className="d-block img-fluid"
          />
        </picture>
      </div>
      {/* hero banner */}
      <div className="mb-1 mb-lg-2">
        <picture className="">
          <source
            media="(max-width:650px)"
            srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/antiDumpingPage/banners/antidumping3.jpg"
          />
          <source
            media="(min-width:651px)"
            srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/antiDumpingPage/banners/antidumping3.jpg"
          />
          <img
            src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/antiDumpingPage/banners/antidumping3.jpg"
            alt="assurance"
            className="d-block img-fluid"
          />
        </picture>
      </div>
      {/* hero banner */}
      <div className="">
        <picture className="">
          <source
            media="(max-width:650px)"
            srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/antiDumpingPage/banners/Dynem+Shippping+banner.jpg"
          />
          <source
            media="(min-width:651px)"
            srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/antiDumpingPage/banners/Dynem+Shippping+banner.jpg"
          />
          <img
            src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/antiDumpingPage/banners/Dynem+Shippping+banner.jpg"
            alt="assurance"
            className="d-block img-fluid"
          />
        </picture>
      </div>
      <div className="line-break"></div>
      <Footer />
    </>
  );
};

export default AntiDumping;
