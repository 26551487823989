import React, { useEffect, useRef, useState } from "react";

const FrameSelection = ({ components, selected, onSelectComponent }) => {
  const handleSelect = (frame) => {
    onSelectComponent(frame);
  };

  const [isOverflowing, setIsOverflowing] = useState(false);
  const listRef = useRef(null);

  const checkOverflow = () => {
    if (listRef.current) {
      setIsOverflowing(
        listRef.current.scrollWidth > listRef.current.clientWidth
      );
    }
  };

  useEffect(() => {
    checkOverflow(); // Initial check
    window.addEventListener("resize", checkOverflow); // Check on resize
    return () => window.removeEventListener("resize", checkOverflow); // Clean up on unmount
  }, []);

  return (
    <div className="pt-4">
      <div
        ref={listRef}
        className={`parameter-list px-4 mb-3 ${
          isOverflowing ? "overflowing" : ""
        }`}
      >
        {components.map((frame) => (
          <div
            key={frame.id}
            className={`text-center mb-4 component-card ${
              selected && selected.id === frame.id ? "selected" : ""
            }`}
            onClick={() => handleSelect(frame)}
          >
            <img
              src={frame.image}
              alt={frame.name}
              className="img-fluid component-image"
            />
            <p
              className={`m-0 mt-3 fw-bold component-text ${
                selected && selected.id === frame.id ? "selected-text" : ""
              }`}
            >
              {frame.name}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FrameSelection;
