import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Meta from "../components/Meta";
import { Link } from "react-router-dom";
import Modal from "../components/Modal";
import ModalMobile from "../components/ModalMobile";

const DTS = () => {
  const dtsHighlights = [
    {
      name: "Customised E-bikes",
      url: "/custom-bike",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/dtsPage/customE-bike.jpg",
      description:
        "Your ebike brand can swiftly shift with customization to our ebikes, enabling your existing ebikes with technology or changes that makes them better, more economical and improve performance like never before.",
    },
    {
      name: "Precision Controllers",
      url: "/components/auto-logic",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/dtsPage/mt-controller.jpg",
      description:
        "Controllers in an e-bike employs sensors and algorithms to adjust power delivery seamlessly and its intelligent system enhances performance and makes the e-bike more intuitive and user-friendly.",
    },
    {
      name: "High Performance Chargers",
      url: "/components/derailleur",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/dtsPage/charger.jpg",
      description:
        "The chargers come in a range of customisable options that cater to preferences, based on global compatibility, portability or extended power flexibility.",
    },
    {
      name: "Multifunctional Displays",
      url: "/components/display",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/dtsPage/display.jpg",
      description:
        "These LCD displays offer real-time data like speed and battery which empowers riders to monitor and adjust their riding modes.",
    },
    {
      name: "Long Lasting Batteries",
      url: "/components/volt-dynamics",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/dtsPage/battery.jpg",
      description:
        "This electric system stores energy and powers the electric motor. This technology has been designed to ensure consistent performance, longer battery life, and optimal power delivery, enhancing the overall riding experience.",
    },
    {
      name: "Efficient Motors",
      url: "/components/motor",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/dtsPage/motor.jpg",
      description:
        "Our versatile and innovative motors have been designed to offer reliable and accurate performance, ensuring optimal utility in various applications.",
    },
  ];
  return (
    <>
      <Meta
        title={
          "E-bike Components & Parts | Electric Bike Parts & Drive Train Systems"
        }
        description={
          "Explore e-bike components and parts at Dynem. Find high-quality electric bike parts and drive train systems to enhance your e-bike's performance."
        }
        cannonicalTag={"https://www.dynem.in/drive-train-systems"}
      />
      <Navbar />
      {/* hero banner */}
      {/* <div className="">
        <picture className="main-banner-img">
          <source
            media="(max-width:650px)"
            srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/mobile/main+banner/Artboard+2+(1).jpg"
          />
          <source
            media="(min-width:651px)"
            srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/main+banner/Artboard+1.jpg"
          />
          <img
            src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/main+banner/Artboard+1.jpg"
            alt="hero-banner-homepage"
            className="d-block w-100"
          />
        </picture>
      </div> */}
      {/* dts cards */}
      <div className="container my-4 mt-lg-5">
        <h1 className="text-center mb-5">DRIVE TRAIN SYSTEMS</h1>
        <div
          className="row d-flex flex-wrap justify-content-center px-3 px-lg-0"
          style={{ gap: "2em" }}
        >
          {dtsHighlights.map((product, i) =>
            product.name === "Customised E-bikes" ? (
              <div className="col-12 col-lg-3 mb-4 listing-category-cards p-0">
                <Link
                  className="text-decoration-none"
                  style={{ color: "inherit" }}
                  to={`${product.url}`}
                >
                  <img
                    src={product.image}
                    alt={product.name}
                    className="w-100"
                  />
                  <div className="p-3 pb-3 pb-lg-0 dts-content">
                    <h1 className="h2-headings">{product.name}</h1>
                    <p>{product.description}</p>
                  </div>
                  <div
                    className="p-3 pb-4"
                    // style={{
                    //   borderRight: "0.5px solid black",
                    //   borderLeft: "0.5px solid black",
                    //   borderBottom: "0.5px solid black",
                    // }}
                  >
                    <button className="d-none d-lg-block w-100 primary-button">
                      {" "}
                      Build Your E-Bike
                    </button>
                  </div>
                </Link>
              </div>
            ) : (
              <div className="col-12 col-lg-3 mb-4 listing-category-cards p-0">
                <img src={product.image} alt={product.name} className="w-100" />
                <div className="p-3 pb-3 pb-lg-0 dts-content">
                  <h1 className="h2-headings">{product.name}</h1>
                  <p>{product.description}</p>
                </div>
                <div
                  className="p-3 pb-4"
                  // style={{
                  //   borderRight: "0.5px solid black",
                  //   borderLeft: "0.5px solid black",
                  //   borderBottom: "0.5px solid black",
                  // }}
                >
                  <Modal className="d-none d-lg-block w-100 primary-button" />
                  <ModalMobile className="d-lg-none w-100 primary-button" />
                </div>
              </div>
            )
          )}
        </div>
        {/* <div className="grid-container px-lg-5">
          {dtsHighlights.map((product, i) => (
            <div className="category-cards mb-4 listing-category-cards">
              {product.name === "Customised E-bikes" ? (
                <Link
                  className="text-decoration-none"
                  style={{ color: "inherit" }}
                  to={`${product.url}`}
                >
                  <img
                    src={product.image}
                    alt={product.name}
                    className="w-100"
                  />
                  <div className="p-3 pb-3 pb-lg-0 dts-content">
                    <h1 className="h2-headings">{product.name}</h1>
                    <p>{product.description}</p>
                  </div>
                </Link>
              ) : (
                <>
                  <img
                    src={product.image}
                    alt={product.name}
                    className="w-100"
                  />
                  <div className="p-3 pb-3 pb-lg-0 dts-content">
                    <h1 className="h2-headings">{product.name}</h1>
                    <p>{product.description}</p>
                  </div>
                </>
              )}
            </div>
          ))}
        </div> */}
      </div>
      <div className="line-break"></div>
      <Footer />
    </>
  );
};

export default DTS;
