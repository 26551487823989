import React, { useEffect } from "react";

const WhatsAppBot = ({ bottom }) => {
  return (
    <>
      <a
        href="https://api.whatsapp.com/send/?phone=+918956703515&text=hi&app_absent=0"
        className="wa-chat-btn-fixed-wrap"
        target="_blank"
      >
        <img
          className="wa-chat-btn-icon-image"
          alt="Whatsapp Chat Button"
          src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/test/whatsapp+logo.png"
        />{" "}
      </a>
    </>
  );
};

WhatsAppBot.defaultProps = {
  bottom: "5%",
};

export default WhatsAppBot;
