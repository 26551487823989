import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../css/aboutUs.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/css";
import { Icon } from "@iconify/react";
import Meta from "../components/Meta";

const AboutUs = () => {
  return (
    <>
      <Meta
        cannonicalTag={"https://www.dynem.in/about-us"}
        title={"About Us | Dynem, Leading E-bike Manufacturer From India"}
        description={
          "Learn about Dynem, the top ebike manufacturer and wholesale electric bike supplier worldwide. Discover our commitment to quality and innovation in electric bikes."
        }
      />
      <Navbar />
      {/* hero banner */}
      <div className="position-relative">
        <picture className="main-banner-img">
          <source
            media="(max-width:650px)"
            srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/aboutUs/factoryMobileBanner.jpg"
          />
          <source
            media="(min-width:651px)"
            srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/aboutUs/factoryDesktopBanner.jpg"
          />
          <img
            src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/aboutUs/factoryDesktopBanner.jpg"
            alt="factory"
            className="d-block img-fluid"
          />
        </picture>
        <div className="overlay-1">
          <div className="position-absolute about-us-banner-heading">
            <h1 className="banner-heading pb-2 mb-3">OUR GIGAFACTORY</h1>
            {/* <br></br> */}
            <p className="banner-text text-light">
              State of the Art Equipment to Design the Best E-bikes in the World
            </p>
          </div>
        </div>
      </div>
      {/* factory */}
      <div className="px-3 px-lg-5 py-4 py-lg-5 container">
        <Splide
          className="mb-3 p-0 align-items-center d-lg-none"
          options={{
            arrows: true,
            drag: false,
            type: "loop",
            pagination: false,
            autoplay: true,
            perMove: 1,
            gap: "2em",
            padding: "0em",
            pauseOnHover: false,
            resetProgress: false,
            perPage: 1,
            interval: 3000,
            speed: 1000,
            breakpoints: {
              700: {
                type: "slide",
                drag: true,
                perPage: 1.2,
                arrows: false,
                pagination: true,
                autoplay: true,
                perMove: 1,
                interval: 4000,
                gap: "1em",
                padding: "0em",
                pauseOnHover: false,
                resetProgress: false,
                focus: 0,
                start: 0,
                speed: 500,
              },
            },
          }}
          muted={true}
        >
          <SplideSlide>
            <div className="position-relative category-card">
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/desktop/factoryImages/2.jpg"
                alt="gigafactory"
                className="d-block w-100"
              />
            </div>
          </SplideSlide>
          <SplideSlide>
            <div className="position-relative category-card">
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/desktop/factoryImages/3.jpg"
                alt="gigafactory"
                className="d-block w-100"
              />
            </div>
          </SplideSlide>
          <SplideSlide>
            <div className="position-relative category-card">
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/desktop/factoryImages/4.jpg"
                alt="gigafactory"
                className="d-block w-100"
              />
            </div>
          </SplideSlide>
          <SplideSlide>
            <div className="position-relative category-card">
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/desktop/factoryImages/6.jpg"
                alt="gigafactory"
                className="d-block w-100"
              />
            </div>
          </SplideSlide>
          <SplideSlide>
            <div className="position-relative category-card">
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/desktop/factoryImages/7.jpg"
                alt="gigafactory"
                className="d-block w-100"
              />
            </div>
          </SplideSlide>
          <SplideSlide>
            <div className="position-relative category-card">
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/desktop/factoryImages/10.jpg"
                alt="gigafactory"
                className="d-block w-100"
              />
            </div>
          </SplideSlide>
        </Splide>
        <div className="d-lg-flex justify-space-between align-items-center">
          <div className="col-lg-6 pe-lg-5">
            <ul>
              <li className="factory-text">
                The factory at DYNEM is the biggest integrated e-cycle
                gigafactory in the World.
              </li>
              <li className="factory-text">
                Our state-of-the-art infrastructure is equipped with modern
                technology to meet the future upgrades in tech.
              </li>
              <li className="factory-text">
                Spanning 240,000 square feet and built in to innovate for the
                future; our gigafactory has employed +800 people who work at the
                factory.
              </li>
              <li className="factory-text">
                DYNEM has the capacity to produce and customise 500,000 e-bikes
                every year and 400,000 units of every electronic component in
                its own category annually.
              </li>
              <li className="factory-text">
                The factory is situated in a mini green paradise amidst
                picturesque surroundings and it inspires creativity and
                innovation.
              </li>
              <li className="factory-text">
                Our state-of-the-art infrastructure is equipped with modern
                technology to meet the future upgrades in tech.
              </li>
            </ul>
          </div>
          <div className="col-lg-6 d-none d-lg-block pt-5">
            <Splide
              className="mb-5 p-0 align-items-center"
              options={{
                arrows: true,
                drag: false,
                type: "loop",
                pagination: false,
                autoplay: true,
                perMove: 1,
                gap: "2em",
                padding: "0em",
                pauseOnHover: false,
                resetProgress: false,
                perPage: 1,
                interval: 3000,
                speed: 1000,
                breakpoints: {
                  700: {
                    type: "slide",
                    drag: true,
                    perPage: 1.2,
                    arrows: false,
                    pagination: true,
                    autoplay: false,
                    perMove: 1,
                    interval: 4000,
                    gap: "1em",
                    padding: "0em",
                    pauseOnHover: false,
                    resetProgress: false,
                    focus: 0,
                    start: 0,
                    speed: 500,
                  },
                },
              }}
              muted={true}
            >
              <SplideSlide>
                <div className="position-relative category-card">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/desktop/factoryImages/2.jpg"
                    alt="gigafactory"
                    className="d-block w-100"
                  />
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="position-relative category-card">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/desktop/factoryImages/3.jpg"
                    alt="gigafactory"
                    className="d-block w-100"
                  />
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="position-relative category-card">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/desktop/factoryImages/4.jpg"
                    alt="gigafactory"
                    className="d-block w-100"
                  />
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="position-relative category-card">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/desktop/factoryImages/6.jpg"
                    alt="gigafactory"
                    className="d-block w-100"
                  />
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="position-relative category-card">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/desktop/factoryImages/7.jpg"
                    alt="gigafactory"
                    className="d-block w-100"
                  />
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="position-relative category-card">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/desktop/factoryImages/10.jpg"
                    alt="gigafactory"
                    className="d-block w-100"
                  />
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </div>
      </div>
      {/* rajib factory */}
      <div className="position-relative mb-4 mb-lg-0">
        <img
          src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/aboutUs/rajib-factory_desktop.jpg"
          alt="factory"
          className="d-block img-fluid"
        />
        <div className="position-absolute rajib-banner-content">
          <h2 className="text-uppercase text-white">
            Invest in the Gold Standard Manufacturing Ebikes for the World
          </h2>
          <br></br>
          <div className="usp-item text-white">
            <ul>
              <li className="rajib-banner-text m-0 ps-3">
                Our commitment to quality and efficiency is backed by our modern
                technology and ensures that every product leaving our factory
                meets the highest standard.
              </li>
              <li className="rajib-banner-text m-0 ps-3">
                Our OEM service allows our clients to customise with their
                branding, specifications, and features.
              </li>
              <li className="rajib-banner-text m-0 ps-3">
                Our goal is to help clients not just succeed but also stand out
                with innovative and reliable solutions tailored to their needs.
              </li>
              <li className="rajib-banner-text m-0 ps-3">
                DYNEM is backed b y experts in the field and we are professional
                in our deliverables, allowing for a smooth flow in the supply
                chain.
              </li>
            </ul>
          </div>
          {/* <p className="rajib-banner-text m-0 ps-3 usp-item">
            Our commitment to quality and efficiency is backed by our modern
            technology and ensures that every product leaving our factory meets
            the highest standard.
          </p>
          <p className="rajib-banner-text m-0 ps-3 usp-item">
            Our OEM service allows our clients to customise with their branding,
            specifications, and features. This service is ideal for clients
            looking to leverage our expertise and production capabilities to
            bring their branded products to market efficiently. Our goal is to
            help clients not just succeed but also stand out with innovative and
            reliable solutions tailored to their needs.
          </p>
          <p className="rajib-banner-text m-0 ps-3 usp-item">
            DYNEM is backed b y experts in the field and we are professional in
            our deliverables, allowing for a smooth flow in the supply chain.
          </p> */}
        </div>
      </div>
      <div className=" d-lg-none mb-2">
        <ul className="">
          <li className=" m-0 ps-3">
            Our commitment to quality and efficiency is backed by our modern
            technology and ensures that every product leaving our factory meets
            the highest standard.
          </li>
          <li className=" m-0 ps-3">
            Our OEM service allows our clients to customise with their branding,
            specifications, and features.
          </li>
          <li className="m-0 ps-3">
            Our goal is to help clients not just succeed but also stand out with
            innovative and reliable solutions tailored to their needs.
          </li>
          <li className=" m-0 ps-3">
            DYNEM is backed b y experts in the field and we are professional in
            our deliverables, allowing for a smooth flow in the supply chain.
          </li>
        </ul>
      </div>
      {/* flags */}
      <div className="px-4 px-lg-5 py-1 py-lg-0 container">
        <div className="py-4 py-lg-5">
          <h2 className="mb-4 text-lg-center globe-heading">
            ACROSS THE GLOBE - <br className="d-lg-none"></br>THE FUTURE BEGINS
            HERE
          </h2>
          <img
            src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/aboutUs/flags.png"
            alt="country-flags"
            className="img-fluid mx-auto d-block"
          ></img>
        </div>
      </div>
      {/* legal anti dumpinhg */}
      <div className="px-3 px-lg-5 py-2 py-lg-3 mt-2 container">
        <h2 className="text-uppercase d-lg-none">
          Legal Anti Dumping Solution
        </h2>
        <div className="d-lg-flex justify-space-between align-items-center">
          <div className="col-lg-6 pe-lg-5 mb-4">
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/aboutUs/containers.jpg"
              alt="containers"
              className="img-fluid"
            ></img>
          </div>
          <div className="col-lg-6 ">
            <h2 className="mb-4 text-uppercase d-none d-lg-block text-center">
              Legal Anti Dumping Solution
            </h2>
            <ul>
              <li className="factory-text m-0 pb-2 ps-3">
                DYNEM is the best choice for importers worldwide as you can save
                up to 87% in taxes through our legal anti dumping solution from
                India. With all the and with all the anti-dumping rules set in
                place, we are proud to declare that our products are free from
                any anti-dumping tax, reaffirming our compliance with global
                regulations while fostering a level playing field.
              </li>
              <li className="factory-text m-0 pb-2 ps-3">
                No anti dumping taxes is an accreditation that we comply with
                which assures companies don't sell their products for low budget
                prices in another country and hurt the businesses in that
                country.
              </li>
              <li className="factory-text m-0  ps-3">
                We ship the goods with text or markings that clearly indicate
                the origination, and are sent to the destination with all the
                required legal documents included. Our commitment to integrity
                and transparency sets us apart as a reliable partner in the
                electric mobility sector.
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* em factory */}
      <div className="px-3 px-lg-5 py-2 py-lg-3 mb-3 mt-2 container">
        <h2 className="text-uppercase d-lg-none">
          State of the Art Equipment Made in India
        </h2>
        <div className="d-lg-flex justify-space-between align-items-center">
          <div className="col-lg-6 d-lg-none pe-lg-5 mb-4">
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/aboutUs/em-factory.jpg"
              alt="factory"
              className="img-fluid"
            ></img>
          </div>
          <div className="col-lg-6 ">
            <h2 className="mb-4 text-uppercase d-none d-lg-block text-center">
              State of the Art Equipment Made in India{" "}
            </h2>
            <div className="">
              <ul>
                <li className="factory-text m-0 pb-2 ps-3">
                  With a diverse product portfolio, including customisable
                  options, we enable our clients to design their e-bikes and
                  tech according to their country’s specifications protocols.
                  Our team of designers, engineers and product developers work
                  closely to create e-bike designs that meet specific
                  requirements.
                </li>
                <li className="factory-text m-0 pb-2 ps-3">
                  Our commitment to quality and efficiency is outdone by our
                  machinery and processes, ensuring that every product leaving
                  our factory meets the highest standard.
                </li>
                <li className="factory-text m-0 ps-3">
                  Our production processes are streamlined and efficient, which
                  allow us to meet the growing demands for sustainable mobility.
                </li>
              </ul>
            </div>
            {/* <p className="factory-text m-0 pb-2 usp-item ps-3">
              With a diverse product portfolio, including customisable options,
              we enable our clients to design their e-bikes and tech according
              to their country's specifications protocols. Our team of
              designers, engineers and product developers work closely to create
              e-bike designs that meet specific requirements.
            </p>
            <p className="factory-text m-0 usp-item ps-3">
              Our commitment to quality and efficiency is outdone by our
              machinery and processes, ensuring that every product leaving our
              factory meets the highest standard. Our production processes are
              streamlined and efficient, which allow us to meet the growing
              demands for sustainable mobility.
            </p> */}
          </div>
          <div className="col-lg-6 d-none d-lg-block ps-lg-5 mb-4">
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/aboutUs/em-factory.jpg"
              alt="factory"
              className="img-fluid"
            ></img>
          </div>
        </div>
      </div>
      {/* certifications */}
      <div className="mb-4">
        <h3 className="text-center">Certifications & Standards</h3>
      </div>
      <div className="container mb-5">
        <img
          src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/desktop/certifications.jpg"
          alt="certifications"
          className="w-100 h-75"
        ></img>
      </div>
      {/* partnering with dynem */}
      <div className="usp-logos p-3 pb-0 p-lg-5 pb-lg-2 mt-2 container">
        <div className="d-lg-flex justify-space-between align-items-center mb-3">
          <h2 className="text-uppercase d-lg-none">
            Partnering with DYNEM - The Best Choice for Importers Worldwide
          </h2>
          <p>
            <em className="dynem-color d-lg-none">Our Clients Count on Us</em>
          </p>
          <div className="col-lg-6 pe-lg-5 mb-4">
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/aboutUs/founders.jpg"
              alt="factory"
              className="img-fluid"
            ></img>
          </div>
          <div className="col-lg-6">
            <h2 className="text-uppercase d-none d-lg-block text-center">
              Partnering with DYNEM - The Best Choice for Importers Worldwide
            </h2>
            <p>
              <em className="dynem-color d-none d-lg-block mb-4 text-center">
                Our Clients Count on Us
              </em>
            </p>
            <div className="">
              <ul>
                <li className="factory-text pb-2 ps-3 m-0">
                  Our cutting-edge and customisable technology will keep you
                  ahead of the game while our strong commitment to
                  sustainability will help you build your market to keep
                  distributing top range products for the long haul.
                </li>
                <li className="factory-text pb-2 ps-3 m-0">
                  DYNEM is backed by experts in the field and we are
                  professional in our deliverables, allowing for a smooth flow
                  in the supply chain.
                </li>
                <li className="factory-text pb-2 ps-3 m-0">
                  We prioritise customer satisfaction by working closely with
                  you to provide tailored solutions, and our dedicated after
                  sales support will be with you from start to finish, making
                  your experience seamless.
                </li>
                <li className="factory-text pb-2 ps-3 m-0">
                  We understand that the real test of a product's quality and
                  reliability comes after it has been purchased. With DYNEM, you
                  can rest assured that you're not just buying a product –
                  you're investing in growth.
                </li>
              </ul>
            </div>
            {/* <p className="factory-text m-0 usp-item pb-2 ps-3">
              Our cutting-edge and customisable technology will keep you ahead
              of the game while our strong commitment to sustainability will
              help you build your market to keep distributing top range products
              for the long haul. DYNEM is backed by experts in the field and we
              are professional in our deliverables, allowing for a smooth flow
              in the supply chain. We prioritise customer satisfaction by
              working closely with you to provide tailored solutions, and our
              dedicated after sales support will be with you from start to
              finish, making your experience seamless.
            </p>
            <p className="factory-text m-0 usp-item ps-3">
              We understand that the real test of a product's quality and
              reliability comes after it has been purchased. With DYNEM, you can
              rest assured that you're not just buying a product – you're
              investing in a growth partner that has your best interests at
              heart. This is what advocates for good partnerships in the long
              haul and makes us best in charge of the production for
              distributing e-bikes across the globe.
            </p> */}
          </div>
        </div>
      </div>
      {/* founders deck */}
      <div className="px-3 px-lg-5 py-4 py-lg-3 mb-4 container">
        <h1 className="mb-4">FOUNDERS</h1>
        <Splide
          className=" p-0 align-items-center d-none d-lg-block"
          options={{
            arrows: false,
            drag: false,
            type: "loop",
            pagination: false,
            autoplay: false,
            perMove: 1,
            gap: "2em",
            padding: "0em",
            pauseOnHover: false,
            resetProgress: false,
            perPage: 4,
            speed: 1000,
            breakpoints: {
              700: {
                perPage: 1,
                arrows: false,
                pagination: true,
                autoplay: true,
                perMove: 1,
                interval: 4000,
                gap: "1em",
                padding: "0em",
                pauseOnHover: false,
                resetProgress: false,
                focus: 0,
                start: 0,
                speed: 700,
              },
            },
          }}
          muted={true}
        >
          <SplideSlide>
            <div className="">
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/desktop/founders/rajib.webp"
                alt="Rajib Gangopadhyay"
                className="d-block w-100"
              />
              <div className="bike-catgories listing-category-cards p-3 founder-text">
                <p className="mb-1">Founder & MD</p>
                <div className="d-flex align-items-baseline">
                  <h2 className="dynem-color mb-3 me-2 founder-name">
                    Rajib Gangopadhyay
                  </h2>
                  <a
                    href="https://www.linkedin.com/in/rajib-gangopadhyay-5135281b/"
                    className="text-decoration-none"
                  >
                    <Icon icon="logos:linkedin-icon" width="1em" height="1em" />
                  </a>
                </div>
                <div className="">
                  <p className="m-0">
                    The founder and tech whiz, Rajib is a visionary leader and
                    accomplished technologist, renowned for his ability to
                    innovate and problem-solve in the field of electric
                    mobility. He can fix a motherboard with a toothpick and a
                    roll of duct tape.
                  </p>
                </div>
              </div>
            </div>
          </SplideSlide>
          <SplideSlide>
            <div className="">
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/desktop/founders/kunal.webp"
                alt="Kunal Gupta"
                className="d-block w-100"
              />
              <div className="bike-catgories listing-category-cards p-3 founder-text">
                <p className="mb-1">CEO & Co-Founder</p>
                <div className="d-flex align-items-baseline">
                  <h2 className="dynem-color mb-3 me-2 founder-name">
                    Kunal Gupta
                  </h2>
                  <a
                    href="https://www.linkedin.com/in/kunal-gupta01/"
                    className="text-decoration-none"
                  >
                    <Icon icon="logos:linkedin-icon" width="1em" height="1em" />
                  </a>
                </div>
                <div className="">
                  <p className="m-0">
                    A dynamic leader and multi-faceted entrepreneur, Kunal's
                    business acumen and ability to navigate the complexities of
                    the business world are reflected in his successful track
                    record. He is also recognized as India's youngest poker
                    champion.
                  </p>
                </div>
              </div>
            </div>
          </SplideSlide>
          <SplideSlide>
            <div className="">
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/desktop/founders/adi.webp"
                alt="Aditya Oza"
                className="d-block w-100"
              />
              <div className="bike-catgories listing-category-cards p-3 founder-text ">
                <p className="mb-1">CMO & Co-Founder</p>
                <div className="d-flex align-items-baseline">
                  <h2 className="dynem-color mb-3 me-2 founder-name">
                    Aditya Oza
                  </h2>
                  <a
                    href="https://www.linkedin.com/in/ozaaditya/"
                    className="text-decoration-none"
                  >
                    <Icon icon="logos:linkedin-icon" width="1em" height="1em" />
                  </a>
                </div>
                <div className="">
                  <p className="m-0">
                    A force within our organisation, likened to a
                    high-performance motor that propels forward with energy,
                    Aditya's innovative marketing strategies and leadership has
                    the ability to transform even the most sluggish campaigns
                    into groundbreaking successes.
                  </p>
                </div>
              </div>
            </div>
          </SplideSlide>
          <SplideSlide>
            <div className="">
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/desktop/founders/sumedh.webp"
                alt="Sumedh Battewar"
                className="d-block w-100"
              />
              <div className="bike-catgories listing-category-cards p-3 founder-text">
                <p className="mb-1">CBO & Co-Founder</p>
                <div className="d-flex align-items-baseline">
                  <h2 className="dynem-color mb-3 me-2 founder-name">
                    Sumedh Battewar
                  </h2>
                  <a
                    href="https://www.linkedin.com/in/sumedh-battewar-39a647147/"
                    className="text-decoration-none"
                  >
                    <Icon icon="logos:linkedin-icon" width="1em" height="1em" />
                  </a>
                </div>
                <div className="">
                  <p className="m-0">
                    A sales maverick, adept at building relationships and
                    closing deals that drive the business forward, he has a deep
                    understanding of the EV industry and a strategic approach to
                    sales. He plays a pivotal role in expanding market reach and
                    driving revenue growth.
                  </p>
                </div>
              </div>
            </div>
          </SplideSlide>
        </Splide>
        <Splide
          className=" p-0 align-items-center d-lg-none"
          options={{
            arrows: false,
            drag: false,
            type: "loop",
            pagination: false,
            autoplay: false,
            perMove: 1,
            gap: "2em",
            padding: "0em",
            pauseOnHover: false,
            resetProgress: false,
            perPage: 4,
            speed: 1000,
            breakpoints: {
              700: {
                drag: true,
                perPage: 2,
                arrows: false,
                pagination: true,
                autoplay: true,
                perMove: 1,
                interval: 5000,
                gap: "1em",
                padding: "0em",
                pauseOnHover: false,
                resetProgress: false,
                focus: 0,
                start: 0,
                speed: 700,
              },
            },
          }}
          muted={true}
        >
          <SplideSlide>
            <div className="">
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/desktop/founders/rajib.webp"
                alt="Rajib Gangopadhyay"
                className="d-block w-100"
              />
              <div className="bike-catgories listing-category-cards p-3 founder-text">
                <p className="mb-1 fw-bold" style={{ fontSize: "0.7em" }}>
                  Founder & MD
                </p>
                <div className="d-flex align-items-baseline">
                  <p
                    className="dynem-color mb-3 me-2 fw-bold"
                    style={{ fontSize: "0.9em" }}
                  >
                    Rajib Gangopadhyay
                  </p>
                  <a
                    href="https://www.linkedin.com/in/rajib-gangopadhyay-5135281b/"
                    className="text-decoration-none"
                  >
                    <Icon
                      icon="logos:linkedin-icon"
                      width="0.8em"
                      height="0.8em"
                    />
                  </a>
                </div>
                <div className="">
                  <p className="m-0">
                    The founder and tech whiz, Rajib is a visionary leader and
                    accomplished technologist, renowned for his ability to
                    innovate and problem-solve in the field of electric
                    mobility. He can fix a motherboard with a toothpick and a
                    roll of duct tape.
                  </p>
                </div>
              </div>
            </div>
          </SplideSlide>
          <SplideSlide>
            <div className="">
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/desktop/founders/kunal.webp"
                alt="Kunal Gupta"
                className="d-block w-100"
              />
              <div className="bike-catgories listing-category-cards p-3 founder-text">
                <p className="mb-1 fw-bold" style={{ fontSize: "0.7em" }}>
                  CEO & Co-Founder
                </p>
                <div className="d-flex align-items-baseline">
                  <p
                    className="dynem-color mb-3 me-2 fw-bold"
                    style={{ fontSize: "0.9em" }}
                  >
                    Kunal Gupta
                  </p>
                  <a
                    href="https://www.linkedin.com/in/kunal-gupta01/"
                    className="text-decoration-none"
                  >
                    <Icon
                      icon="logos:linkedin-icon"
                      width="0.8em"
                      height="0.8em"
                    />
                  </a>
                </div>
                <div className="">
                  <p className="m-0">
                    A dynamic leader and multi-faceted entrepreneur, Kunal's
                    business acumen and ability to navigate the complexities of
                    the business world are reflected in his successful track
                    record. He is also recognized as India's youngest poker
                    champion.
                  </p>
                </div>
              </div>
            </div>
          </SplideSlide>
          <SplideSlide>
            <div className="">
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/desktop/founders/adi.webp"
                alt="Aditya Oza"
                className="d-block w-100"
              />
              <div className="bike-catgories listing-category-cards p-3 founder-text">
                <p className="mb-1 fw-bold" style={{ fontSize: "0.7em" }}>
                  CMO & Co-Founder
                </p>
                <div className="d-flex align-items-baseline">
                  <p
                    className="dynem-color mb-3 me-2 fw-bold"
                    style={{ fontSize: "0.9em" }}
                  >
                    Aditya Oza
                  </p>
                  <a
                    href="https://www.linkedin.com/in/ozaaditya/"
                    className="text-decoration-none"
                  >
                    <Icon
                      icon="logos:linkedin-icon"
                      width="0.8em"
                      height="0.8em"
                    />
                  </a>
                </div>
                <div className="">
                  <p className="m-0">
                    A force within our organisation, likened to a
                    high-performance motor that propels forward with energy,
                    Aditya's innovative marketing strategies and leadership has
                    the ability to transform even the most sluggish campaigns
                    into groundbreaking successes.
                  </p>
                </div>
              </div>
            </div>
          </SplideSlide>
          <SplideSlide>
            <div className="">
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/desktop/founders/sumedh.webp"
                alt="Sumedh Battewar"
                className="d-block w-100"
              />
              <div className="bike-catgories listing-category-cards p-3 founder-text">
                <p className="mb-1 fw-bold" style={{ fontSize: "0.7em" }}>
                  CBO & Co-Founder
                </p>
                <div className="d-flex align-items-baseline">
                  <p
                    className="dynem-color mb-3 me-2 fw-bold"
                    style={{ fontSize: "0.9em" }}
                  >
                    {" "}
                    Sumedh Battewar
                  </p>
                  <a
                    href="https://www.linkedin.com/in/sumedh-battewar-39a647147/"
                    className="text-decoration-none"
                  >
                    <Icon
                      icon="logos:linkedin-icon"
                      width="0.8em"
                      height="0.8em"
                    />
                  </a>
                </div>
                <div className="">
                  <p className="m-0">
                    A sales maverick, adept at building relationships and
                    closing deals that drive the business forward, he has a deep
                    understanding of the EV industry and a strategic approach to
                    sales. He plays a pivotal role in expanding market reach and
                    driving revenue growth.
                  </p>
                </div>
              </div>
            </div>
          </SplideSlide>
        </Splide>
      </div>
      <div className="line-break"></div>
      <Footer />
    </>
  );
};

export default AboutUs;
