import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import "../css/landingPage.css";
import cst from "../images/partnerLogos/cst.png";
import kenli from "../images/partnerLogos/kenli.png";
import kmc from "../images/partnerLogos/kmc.png";
import maxxis from "../images/partnerLogos/maxxis.png";
import wtb from "../images/partnerLogos/wtb.png";
import schwalbe from "../images/partnerLogos/schwalbe.png";
import neco from "../images/partnerLogos/neco.png";
import ralson from "../images/partnerLogos/ralson.png";
import prowheel from "../images/partnerLogos/prowheel.png";
import sunrace from "../images/partnerLogos/sunrace.png";
import shimano from "../images/partnerLogos/shimano.png";
import sram from "../images/partnerLogos/sram.png";
import rockshox from "../images/partnerLogos/rockshox.png";
import microshift from "../images/partnerLogos/microshift.png";
import axios from "axios";
import PhoneInput from "react-phone-number-input";
import Meta from "../components/Meta";
import Modal from "../components/Modal";
import ModalMobile from "../components/ModalMobile";
import ReactPlayer from "react-player";
import CustomBike from "../components/CustomBike";
import "../css/navbar.css";
import logo from "../images/brandLogos/Dynem red white.png";
import { Icon } from "@iconify/react";

const GetQuoteGerman = () => {
  const featuresData = [
    {
      key: "precisionControllers",
      heading: "Präzisionssteuerungen",
      description:
        "Controller in einem E-Bike nutzen Sensoren und Algorithmen, um die Leistungsabgabe nahtlos anzupassen. Das intelligente System steigert die Leistung und macht das E-Bike intuitiver und benutzerfreundlicher.",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/Controlerrrr.png",
    },
    {
      key: "highPerformanceChargers",
      heading: "Hochleistungsladegeräte",
      description:
        "Die Ladegeräte sind in einer Reihe anpassbarer Optionen erhältlich, die Ihren Vorlieben gerecht werden, basierend auf globaler Kompatibilität, Portabilität oder erweiterter Energieflexibilität.",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/Chargerrrr.png",
    },
    {
      key: "multifunctionalDisplays",
      heading: "Multifunktionale Displays",
      description:
        "Diese LCD-Displays bieten Echtzeitdaten wie Geschwindigkeit und Akku, sodass Fahrer ihre Fahrmodi überwachen und anpassen können.",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/Displayyyyy.png",
    },
    {
      key: "longLastingBatteries",
      heading: "Langlebige Batterien",
      description:
        "Dieses elektrische System speichert Energie und treibt den Elektromotor an. Diese Technologie wurde entwickelt, um eine konstante Leistung, eine längere Akkulaufzeit und eine optimale Leistungsabgabe zu gewährleisten und so das Fahrerlebnis insgesamt zu verbessern.",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/Baterryyyyy.png",
    },
    {
      key: "efficientMotors",
      heading: "Effiziente Motoren",
      description:
        "Unsere vielseitigen und innovativen Motoren wurden entwickelt, um zuverlässige und präzise Leistung zu bieten und einen optimalen Nutzen in verschiedenen Anwendungen zu gewährleisten.",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/motorrrr.png",
    },
  ];
  const [activeFeature1, setActiveFeature1] = useState("precisionControllers");
  const handleFeature1Click = (feature) => {
    setActiveFeature1(feature.key);
  };
  const getActiveFeature1 = () => {
    return featuresData.find((feature) => feature.key === activeFeature1);
  };
  const activeFeatureObj1 = getActiveFeature1();
  const [open, openNav] = useState(false);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, []);

  const closeNavforDeadLink = () => {
    openNav(false);
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [designation, setDesignation] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("");
  const [enquiry, setEnquiry] = useState("");

  const componentData = [
    {
      key: "Frame",
    },
    {
      key: "Motor",
    },
    {
      key: "Derailleur",
    },
    {
      key: "Battery",
    },
    {
      key: "Display",
    },
  ];
  const [activeComponent, setActiveComponent] = useState("Frame");
  const handleComponentClick = (feature) => {
    setActiveComponent(feature.key);
  };

  const navigate = useNavigate();

  const submit = async (e) => {
    e.preventDefault();
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const reqBody = {
      name,
      email,
      phoneNumber,
      companyName,
      designation,
      country,
      enquiry,
    };
    const { data } = await axios.post(
      "/api/form/request-a-quote",
      reqBody,
      config
    );
    if (data) {
      navigate("/details-submitted");
    }
  };
  return (
    <>
      {/* landing page main banner */}
      <Meta
        title={"Get a Quote - Get Wholesale E-bike Purchase Quote from Dynem"}
        description={
          "Get a wholesale e-bike purchase quote from Dynem, a leading e-bike manufacturer and supplier. Request your personalized quote today for bulk orders."
        }
        cannonicalTag={"https://www.dynem.in/get-quote/de"}
      />
      {/* desktop navbar */}
      <div
        className="position-sticky w-100 navbar"
        style={{ zIndex: "99", top: "0%" }}
      >
        <div className="row container mx-auto px-3 px-lg-0">
          <div className="col-12 col-md-4 d-flex justify-content-start p-0">
            <Link to={"/"}>
              <img src={logo} alt="dynem-logo" className="logo"></img>
            </Link>
          </div>
          <div className="col-5 d-flex nav-items p-0"></div>
          <div className="col-3 p-0 d-none d-lg-block d-md-block">
            <div className="d-flex justify-content-end align-items-center">
              <Modal
                language={"german"}
                className="primary-button fw-bold px-4 py-2"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="get-quote-landing">
        {/* hero-banner */}
        <div className="hero-banner position-relative">
          <picture className="main-banner-img">
            <source
              media="(max-width:650px)"
              srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/banners/heroBanner_mobile.jpg"
            />
            <source
              media="(min-width:651px)"
              srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/banners/heroBanner.jpg"
            />
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/banners/heroBanner.jpg"
              alt="hero-banner"
              className="d-block w-100"
            />
          </picture>
          <div
            className="d-none d-lg-block position-absolute"
            style={{ top: "10%", left: "7%" }}
          >
            <h1
              className="poppins-bold text-light"
              style={{ fontSize: "3.5em" }}
            >
              <span style={{ color: "#ff0000" }}>BRINGEN SIE IHRE</span>
              <br></br>
              E-BIKE-PRODUKTION IN SCHWUNG
            </h1>
            <p className="text-light w-50">
              Steigern Sie Ihre E-Bike-Produktion mit der Präzisionstechnik und
              fachmännischen Handwerkskunst von Dynem und liefern Sie
              erstklassige Ergebnisse, die Ihre Marke von anderen abheben.
            </p>
          </div>
          <div className="d-lg-none position-absolute" style={{ top: "0%" }}>
            <div className="d-flex justify-content-center w-100 p-4">
              <h1
                className="poppins-bold text-light"
                style={{ fontSize: "2.5em" }}
              >
                <span style={{ color: "#ff0000" }}>BRINGEN SIE IHRE</span>
                <br></br>
                E-BIKE-PRODUKTION IN SCHWUNG
              </h1>
            </div>
          </div>
          <div
            className="d-lg-block d-none position-absolute"
            style={{
              bottom: "10%",
              left: "7%",
              backgroundColor: "rgba(151, 151, 151, 0.56)",
            }}
          >
            <div
              className="d-flex p-3 px-5 text-center poppins-medium"
              style={{
                gap: "2em",
              }}
            >
              <h5 className="text-light">
                5,00,000+<br></br>E-Bike-Kapazität
              </h5>
              <h5 className="text-light">
                4,00,000+<br></br>Komponentenkapazität
              </h5>
              <h5 className="text-light">
                Eigener<br></br>Antriebsstrang
              </h5>
              <Modal
                language={"german"}
                className="primary-button fw-bold px-4 py-2"
              />
            </div>
          </div>
          <div
            className="d-lg-none position-absolute w-100"
            style={{
              bottom: "10%",
              backgroundColor: "rgba(151, 151, 151, 0.56)",
            }}
          >
            <div
              className="d-flex p-3 text-center poppins-medium"
              style={{
                gap: "1.7em",
                fontSize: "0.8em",
              }}
            >
              <h5 className="text-light">
                5,00,000+<br></br>E-Bike-Kapazität
              </h5>
              <h5 className="text-light">
                4,00,000+<br></br>Komponenten- kapazität
              </h5>
              <h5 className="text-light">
                Eigener<br></br>Antriebsstrang
              </h5>
            </div>
          </div>
          <div className="d-lg-none get-quote-banner-button-mb position-absolute">
            <div className="d-flex" style={{ gap: "1.5em" }}>
              <ModalMobile
                language={"german"}
                className="primary-button fw-bold px-4 py-2"
              />
            </div>
          </div>
        </div>
        {/* world class products */}
        <div className="">
          <div className=" mt-5 py-4 py-lg-5">
            <h2 className="poppins-medium mb-3 mb-lg-5 text-center container">
              Erstklassige Fertigung aus Indien für die Welt!
            </h2>
            <div className="news py-5">
              <Splide
                className="mb-2 mb-lg-0 p-0 align-items-center container"
                options={{
                  arrows: false,
                  drag: false,
                  type: "slide",
                  pagination: false,
                  autoplay: false,
                  perMove: 1,
                  gap: "2em",
                  padding: "0em",
                  pauseOnHover: true,
                  resetProgress: false,
                  perPage: 4,
                  speed: 1000,
                  interval: 3000,
                  breakpoints: {
                    700: {
                      drag: true,
                      perPage: 1.3,
                      arrows: false,
                      perMove: 1,
                      gap: "1em",
                      padding: "0em",
                      pauseOnHover: false,
                      resetProgress: false,
                      focus: 0,
                      start: 0,
                      speed: 1000,
                      interval: 3000,
                    },
                  },
                }}
                muted={true}
              >
                <SplideSlide className="h-100">
                  <div className="p-3" style={{ backgroundColor: "white" }}>
                    <div>
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/products/ecycle.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <h5 className="poppins-medium mt-3 text-center">E-Bikes</h5>
                  </div>
                </SplideSlide>
                <SplideSlide className="h-100">
                  <div className="p-3" style={{ backgroundColor: "white" }}>
                    <div>
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/products/motor.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <h5 className="poppins-medium mt-3 text-center">Motor</h5>
                  </div>
                </SplideSlide>
                <SplideSlide className="h-100">
                  <div className="p-3" style={{ backgroundColor: "white" }}>
                    <div>
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/products/battery.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <h5 className="poppins-medium mt-3 text-center">
                      Batterie
                    </h5>
                  </div>
                </SplideSlide>
                <SplideSlide className="h-100">
                  <div className="p-3" style={{ backgroundColor: "white" }}>
                    <div>
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/products/dispaly.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <h5 className="poppins-medium mt-3 text-center">Anzeige</h5>
                  </div>
                </SplideSlide>
              </Splide>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center py-4">
            <Modal
              language={"german"}
              className="primary-button fw-bold px-4 py-2 d-lg-block d-none"
            />
            <ModalMobile
              language={"german"}
              className="primary-button fw-bold px-4 py-2 d-lg-none"
            />
          </div>
        </div>
        {/* company logos */}
        <div className="container my-5 py-lg-5 d-none d-lg-block">
          <Splide
            className="flex align-items-center"
            extensions={{ AutoScroll }}
            options={{
              type: "loop",
              autoScroll: {
                speed: 1,
                pauseOnHover: false,
              },
              perPage: 6,
              drag: "free",
              arrows: false,
              pagination: false,
              pauseOnHover: false,
              gap: "3em",
              padding: "1em",
              breakpoints: {
                640: {
                  perPage: 3,
                  gap: "1em",
                },
              },
            }}
          >
            <SplideSlide className="h-100">
              <div>
                <img src={shimano} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={kmc} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={maxxis} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={wtb} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={rockshox} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={neco} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={ralson} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={sunrace} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={microshift} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={sram} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={schwalbe} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
          </Splide>
        </div>
        <div className="my-5 py-lg-5 d-lg-none">
          <Splide
            className="flex align-items-center"
            extensions={{ AutoScroll }}
            options={{
              type: "loop",
              autoScroll: {
                speed: 1,
                pauseOnHover: false,
              },
              perPage: 6,
              drag: "free",
              arrows: false,
              pagination: false,
              pauseOnHover: false,
              gap: "3em",
              padding: "1em",
              breakpoints: {
                640: {
                  perPage: 3,
                  gap: "1em",
                },
              },
            }}
          >
            <SplideSlide className="h-100">
              <div>
                <img src={shimano} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={kmc} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={maxxis} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={wtb} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={rockshox} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={neco} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={ralson} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={sunrace} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={microshift} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={sram} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={schwalbe} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
          </Splide>
        </div>
        {/* why choose us */}
        <div className="news">
          <div className="container mt-5 pt-lg-5 pt-5">
            <h2 className="poppins-medium mb-2">Ihr Partner für Erfolg</h2>
            <h4 className="">
              Hier erfahren Sie, warum wir uns von anderen abheben
            </h4>
            <div className="py-5">
              <div className="d-flex flex-wrap justify-content-between text-center w-100 mb-3 mb-lg-5 why-choose-us">
                <div className="flex-item">
                  <div className="d-flex justify-content-center mb-3">
                    <img
                      className="img-fluid"
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/production.png"
                    ></img>
                  </div>

                  <p className="m-0 poppins-medium">
                    500,000<br></br>Produktionskapazität
                  </p>
                </div>
                <div className="flex-item">
                  <div className="d-flex justify-content-center mb-3">
                    <img
                      className="img-fluid"
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/component.png"
                    ></img>
                  </div>
                  <p className="m-0 poppins-medium">
                    400,000<br></br>Komponenten- kapazitäts
                  </p>
                </div>
                <div className="flex-item">
                  <div className="d-flex justify-content-center mb-3">
                    <img
                      className="img-fluid"
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/cost.png"
                    ></img>
                  </div>
                  <p className="m-0 poppins-medium">
                    87% <br></br>Kostenvorteile
                  </p>
                </div>
                <div className="flex-item">
                  <div className="d-flex justify-content-center mb-3">
                    <img
                      className="img-fluid"
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/iot.png"
                    ></img>
                  </div>
                  <p className="m-0 poppins-medium">
                    IOT aktiviert<br></br>Lösungen
                  </p>
                </div>
              </div>
              <div className="d-flex flex-wrap flex-wrap-2 justify-content-between text-center w-100 why-choose-us">
                <div className="flex-item">
                  <div className="d-flex justify-content-center mb-3">
                    <img
                      className="img-fluid"
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/certificate.png"
                    ></img>
                  </div>

                  <p className="mt-2 poppins-medium">
                    Europa<br></br>Zertifizierungen
                  </p>
                </div>
                <div className="flex-item">
                  <div className="d-flex justify-content-center mb-3">
                    <img
                      className="img-fluid"
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/in-stock.png"
                    ></img>
                  </div>
                  <p className="mt-2 poppins-medium">
                    Bereiter Vorrat<br></br>verfügbar
                  </p>
                </div>
                <div className="flex-item">
                  <div className="d-flex justify-content-center mb-3">
                    <img
                      className="img-fluid"
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/automation.png"
                    ></img>
                  </div>
                  <p className="mt-2 poppins-medium">
                    Alle Komponenten<br></br>Eigenproduktion
                  </p>
                </div>
                <div className="flex-item">
                  <div className="d-flex justify-content-center mb-3">
                    <img
                      className="img-fluid"
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/container.png"
                    ></img>
                  </div>
                  <p className="mt-2 poppins-medium">
                    Gesetzliches Antidumping<br></br>Lösung
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* range of dts */}
        <div className="container mt-5 pt-4 pt-lg-5">
          <h2 className="poppins-medium mb-4 mb-lg-5">
            Komponenten, die jeden Kilometer mit Energie versorgen
          </h2>
          <div className="d-lg-none pt-4">
            <Splide
              className="mb-5 p-0 align-items-center"
              options={{
                drag: true,
                perPage: 1.2,
                arrows: false,
                pagination: false,
                autoplay: false,
                perMove: 1,
                interval: 4000,
                gap: "1em",
                padding: "0em",
                pauseOnHover: false,
                resetProgress: false,
                focus: 0,
                start: 0,
                speed: 500,
              }}
            >
              {featuresData.slice(0, 4).map((feature) => (
                <SplideSlide className="feature-btn-width">
                  <button
                    key={feature}
                    className={
                      feature.key === activeFeature1
                        ? "em-btn-1 rounded-0"
                        : "em-btn-2 rounded-0"
                    }
                    onClick={() => handleFeature1Click(feature)}
                  >
                    {feature.heading}
                  </button>
                </SplideSlide>
              ))}
            </Splide>
          </div>
          <div className="mb-5 d-none d-lg-block">
            <div
              className="d-flex justify-content-center mb-3"
              style={{ gap: "1em" }}
            >
              {featuresData.map((feature) => (
                <button
                  key={feature}
                  className={
                    feature.key === activeFeature1
                      ? "em-btn-1 rounded-0"
                      : "em-btn-2 rounded-0"
                  }
                  onClick={() => handleFeature1Click(feature)}
                >
                  {feature.heading}
                </button>
              ))}
            </div>
          </div>
          <div className="d-none d-lg-block my-5 px-5">
            <div className="d-flex align-items-center justify-content-center">
              <div className="feature-text w-100">
                <h1>{activeFeatureObj1.heading}</h1>{" "}
                <p>{activeFeatureObj1.description}</p>
              </div>
              <div className="w-100">
                <img
                  src={activeFeatureObj1.image}
                  alt="amigo-feature"
                  className="img-fluid"
                ></img>
              </div>
            </div>
          </div>
          <div className="row mt-5 d-lg-none">
            <div className="col-12 col-lg-6 feature-text">
              <h1>{activeFeatureObj1.heading}</h1>{" "}
              <p>{activeFeatureObj1.description}</p>
            </div>
            <div className="col-12 col-lg-6">
              <div className="d-flex d-lg-block justify-content-center">
                <img
                  src={activeFeatureObj1.image}
                  alt="amigo-feature"
                  className="img-fluid mx-auto"
                ></img>
              </div>
            </div>
          </div>
        </div>
        {/* range of e-bikes */}
        <div className="mt-5 pt-lg-5 ">
          <div className="position-relative">
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/banners/breaker2.jpg"
              className="img-fluid"
            ></img>
            <div
              className="position-absolute d-lg-block d-none"
              style={{ top: "25%", left: "58%" }}
            >
              <div>
                <h1
                  className="poppins-medium text-light"
                  style={{ fontSize: "4em" }}
                >
                  Entdecken Sie<br></br>unsere Elektroflotte
                </h1>
              </div>
            </div>
          </div>
          <h2 className="poppins-medium d-lg-none my-4 text-center">
            Entdecken Sie unsere Elektroflotte
          </h2>
          <div className=" py-3 d-lg-none">
            <Splide
              className="mb-2 mb-lg-0 p-0 align-items-center container"
              options={{
                arrows: false,
                drag: false,
                type: "slide",
                pagination: false,
                autoplay: false,
                perMove: 1,
                gap: "2em",
                padding: "0em",
                pauseOnHover: true,
                resetProgress: false,
                perPage: 4,
                speed: 1000,
                interval: 3000,
                breakpoints: {
                  700: {
                    drag: true,
                    perPage: 1.3,
                    arrows: false,
                    perMove: 1,
                    gap: "1em",
                    padding: "0em",
                    pauseOnHover: false,
                    resetProgress: false,
                    focus: 0,
                    start: 0,
                    speed: 1000,
                    interval: 3000,
                  },
                },
              }}
              muted={true}
            >
              <SplideSlide className="h-100">
                <div className="p-3 news">
                  <div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ebikes/mountain.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="mt-3 text-center" style={{ height: "40px" }}>
                    Mountainbikes
                  </h4>
                  <div style={{ height: "160px" }}>
                    <p className="text-center m-0">
                      Wir sind nicht nur ein Hersteller von
                      Mountainbike-E-Bikes; Wir sind Vorreiter im
                      Offroad-Abenteuer. Entdecken Sie eine Reihe von E-Bikes,
                      die sorgfältig für raues Gelände und aufregende Fahrten
                      entwickelt wurden.
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-3 news">
                  <div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ebikes/fat-tyre.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="mt-3 text-center" style={{ height: "40px" }}>
                    Falt- und Fettreifen-E-Bikes
                  </h4>
                  <div style={{ height: "160px" }}>
                    <p className="text-center m-0">
                      Als erstklassiger Hersteller von faltbaren E-Bikes bietet
                      Ihnen DYNEM die ultimative Mischung aus Komfort und Stil.
                      Unsere faltbaren E-Bikes sind perfekt für Stadtentdecker
                      und Jetsetter.
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-3 news">
                  <div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ebikes/city.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="mt-3 text-center" style={{ height: "40px" }}>
                    City-E-Bikes
                  </h4>
                  <div style={{ height: "160px" }}>
                    <p className="text-center m-0">
                      Als führender Hersteller von Stadtfahrrädern definiert
                      DYNEM das Pendeln in der Stadt mit eleganten, effizienten
                      und stilvollen Fahrten neu. Unsere Citybikes vereinen
                      modernste Technologie mit modernem Design und sorgen
                      dafür, dass Sie mühelos durch den Verkehr gleiten.
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-3 news">
                  <div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ebikes/cargo.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="mt-3 text-center" style={{ height: "40px" }}>
                    Lieferung von E-Bikes
                  </h4>
                  <div style={{ height: "160px" }}>
                    <p className="text-center m-0">
                      Als führender Lieferfahrradhersteller liefert DYNEM bei
                      jeder Lieferung erstklassige Leistung und Zuverlässigkeit.
                      Unsere E-Bikes sind auf die Anforderungen der urbanen
                      Logistik, leistungsstarke Motoren und viel Laderaum
                      ausgelegt.
                    </p>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
          <div className="range-ebikes container d-lg-block d-none py-5 my-5">
            <Splide
              className="mb-2 mb-lg-0 p-0 align-items-center text-center"
              options={{
                arrows: true,
                start: 0,
                drag: false,
                type: "loop",
                pagination: true,
                autoplay: true,
                gap: "3em",
                padding: "1em",
                pauseOnHover: true,
                resetProgress: false,
                perPage: 1,
                speed: 1000,
                interval: 4000,
                breakpoints: {
                  700: {
                    drag: true,
                    autoplay: false,
                    pagination: "false",
                    perPage: 1,
                    perMove: 1,
                    gap: "2em",
                    padding: "0em",
                    arrows: false,
                    pauseOnHover: false,
                    resetProgress: false,
                  },
                },
              }}
              muted={true}
            >
              <SplideSlide className="h-100">
                <div className="p-4 pt-0 p-lg-0 d-flex align-items-center ecycle-range-card">
                  <div className="">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ebikes/mountain.png"
                      alt=""
                      className="w-100"
                    />
                  </div>
                  <div className="text-start p-3">
                    <h4 className="poppins-medium">Mountainbikes</h4>
                    <p className="">
                      Wir sind nicht nur ein Hersteller von
                      Mountainbike-E-Bikes; Wir sind Vorreiter im
                      Offroad-Abenteuer. Entdecken Sie eine Reihe von E-Bikes,
                      die sorgfältig für raues Gelände und aufregende Fahrten
                      entwickelt wurden.
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pt-0 p-lg-0 d-flex align-items-center ecycle-range-card">
                  <div className="">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ebikes/fat-tyre.png"
                      alt=""
                      className="w-100"
                    />
                  </div>
                  <div className="text-start p-3">
                    <h4 className="poppins-medium">
                      Falt- und Fettreifen-E-Bikes
                    </h4>
                    <p className="">
                      Als erstklassiger Hersteller von faltbaren E-Bikes bietet
                      Ihnen DYNEM die ultimative Mischung aus Komfort und Stil.
                      Unsere faltbaren E-Bikes sind perfekt für Stadtentdecker
                      und Jetsetter.
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pt-0 p-lg-0 d-flex align-items-center ecycle-range-card">
                  <div className="">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ebikes/city.png"
                      alt=""
                      className="w-100"
                    />
                  </div>
                  <div className="text-start p-3">
                    <h4 className="poppins-medium">City-E-Bikes</h4>
                    <p className="">
                      Als führender Hersteller von Stadtfahrrädern definiert
                      DYNEM das Pendeln in der Stadt mit eleganten, effizienten
                      und stilvollen Fahrten neu. Unsere Citybikes vereinen
                      modernste Technologie mit modernem Design und sorgen
                      dafür, dass Sie mühelos durch den Verkehr gleiten.
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pt-0 p-lg-0 d-flex align-items-center ecycle-range-card">
                  <div className="">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ebikes/cargo.png"
                      alt=""
                      className="w-100"
                    />
                  </div>
                  <div className="text-start p-3">
                    <h4 className="poppins-medium">Lieferung von E-Bikes</h4>
                    <p className="">
                      Als führender Lieferfahrradhersteller liefert DYNEM bei
                      jeder Lieferung erstklassige Leistung und Zuverlässigkeit.
                      Unsere E-Bikes sind auf die Anforderungen der urbanen
                      Logistik, leistungsstarke Motoren und viel Laderaum
                      ausgelegt.
                    </p>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </div>
        {/* certifications */}
        <div className="news mt-5 pt-lg-5 pt-4 mb-5">
          <h2 className="poppins-medium mb-3 mb-lg-4 container">
            Zertifizierungen
          </h2>
          <div className="container pb-4">
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/certifications__png.png"
              alt="certifications"
              className="w-100"
            ></img>
          </div>
        </div>
        <div className="container mt-5 pt-lg-5">
          <h2 className="poppins-medium mb-2">Machen Sie den Wandel</h2>
          <h4 className="mb-3 mb-lg-5">
            Vereinfachen Sie Ihre Produktion mit uns
          </h4>
          <div className="mx-lg-5 switching">
            <Splide
              className="mb-lg-0 p-0 align-items-center"
              options={{
                arrows: true,
                start: 0,
                drag: false,
                type: "loop",
                pagination: true,
                autoplay: true,
                gap: "3em",
                padding: "0em",
                pauseOnHover: true,
                resetProgress: false,
                perPage: 4,
                perMove: 1,
                speed: 1000,
                interval: 4000,
                breakpoints: {
                  700: {
                    drag: true,
                    arrows: false,
                    autoplay: false,
                    perPage: 1,
                    perMove: 1,
                    gap: "0em",
                    padding: "0em",
                    pauseOnHover: false,
                    resetProgress: false,
                  },
                },
              }}
              muted={true}
            >
              <SplideSlide className="h-100 ">
                <div className="p-4 pb-0 pt-0 p-lg-0 mb-4">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/1.png"
                      alt=""
                      className="img-fluid col-6 col-md-10"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">Massenproduktion</h5>
                    <p className="m-0">
                      Ausrichtung auf die Produktion nach Auftragsabschlus
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pb-0 pt-0 p-lg-0 mb-4">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/2.png"
                      alt=""
                      className="img-fluid col-6 col-md-10"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">Probenahmeprozess</h5>
                    <p className="m-0">
                      Ermöglicht auch die Überprüfung der
                      Antidumping-Zollvalidierung
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pb-0 pt-0 p-lg-0 mb-4">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/3.png"
                      alt=""
                      className="img-fluid col-6 col-md-10"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">
                      Entdecken Sie unser Produktangebot
                    </h5>
                    <p className="m-0">
                      Wir passen Ihre Spezifikationen an und bieten ein
                      vollständiges lokalisiertes Angebot (Herkunftsland) an.
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pb-0 pt-0 p-lg-0 mb-4">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/4.png"
                      alt=""
                      className="img-fluid col-6 col-md-10"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">
                      Organisation einer Werksbesichtigung
                    </h5>
                    <p className="m-0">
                      um Ihnen tiefe Einblicke in den hochmodernen Maschinenpark
                      und Herstellungsprozess zu geben
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pb-0 pt-0 p-lg-0 mb-4">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/5.png"
                      alt=""
                      className="img-fluid col-6 col-md-10"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">Produktfinalisierung</h5>
                    <p className="m-0">Nachbemusterung und Chargenprüfung</p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pb-0 pt-0 p-lg-0 mb-4">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/6.png"
                      alt=""
                      className="img-fluid col-6 col-md-10"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">
                      Unterstützung im After-Sales
                    </h5>
                    <p className="m-0">
                      Zusammen mit und Ersatzteilplanung – Europa und USA
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pb-0 pt-0 p-lg-0 mb-4">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/7.png"
                      alt=""
                      className="img-fluid col-6 col-md-10"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">
                      Erweitern Sie die Logistikunterstützung
                    </h5>
                    <p className="m-0">
                      Um die beste Versandlösung mit unserem erweiterten
                      Partnernetzwerk zu ermöglichen
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pb-0 pt-0 p-lg-0 mb-4">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/8.png"
                      alt=""
                      className="img-fluid col-6 col-md-10"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">
                      Senden Sie Ihre Anforderungen
                    </h5>
                    <p className="m-0">(Fahrradbilder und Spezifikationen)</p>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center mt-5">
          <Modal
            language={"german"}
            className="primary-button fw-bold px-4 py-2 d-lg-block d-none"
          />
          <ModalMobile
            language={"german"}
            className="primary-button fw-bold px-4 py-2 d-lg-none"
          />
        </div>
        {/* amigo */}
        <div className="news my-5 py-5">
          <h2 className="poppins-medium mb-5 container">
            Amigo - Eine App | Grenzenlose Möglichkeiten
          </h2>
          <div
            className="d-flex container pt-3"
            style={{ overflowX: "auto", gap: "2em" }}
          >
            <div className="mb-4 amigo-spec">
              <div className="position-relative">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/amigo/fitness.png"
                  className="w-100"
                ></img>
                <div
                  className="position-absolute"
                  style={{ top: "17%", right: "17%" }}
                >
                  <div className="d-flex align-items-end">
                    <p className="fw-bold">Fitnessmodus</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4 amigo-spec">
              <div className="position-relative">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/amigo/diagnostic.png"
                  className="w-100"
                ></img>
                <div
                  className="position-absolute"
                  style={{ top: "13%", right: "12%" }}
                >
                  <div className="d-flex align-items-end">
                    <p className="fw-bold">
                      Komplette<br></br>Fahrraddiagnose
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4 amigo-spec">
              <div className="position-relative">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/amigo/sos.png"
                  className="w-100"
                ></img>
                <div
                  className="position-absolute"
                  style={{ top: "13%", right: "21%" }}
                >
                  <div className="d-flex align-items-end">
                    <p className="fw-bold">
                      Notfall-<br></br>SOS-Alarm
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center mt-4 mt-lg-5">
          <Modal
            language={"german"}
            className="primary-button fw-bold px-4 py-2 d-lg-block d-none"
          />
          <ModalMobile
            language={"german"}
            className="primary-button fw-bold px-4 py-2 d-lg-none"
          />
        </div>
        {/* testimonials */}
        <div className="mt-5 py-lg-5 container testimonials">
          <h2 className="poppins-medium mb-3 mb-lg-5 container">
            Erfahrungsberichte
          </h2>
          <div className="mt-5 mt-lg-0">
            <Splide
              className="mb-lg-0 p-0 align-items-center"
              options={{
                arrows: true,
                start: 0,
                drag: false,
                type: "loop",
                pagination: true,
                autoplay: true,
                gap: "1.5em",
                padding: "0em",
                pauseOnHover: true,
                resetProgress: false,
                perPage: 1,
                perMove: 1,
                speed: 1000,
                interval: 4000,
                breakpoints: {
                  700: {
                    drag: true,
                    arrows: false,
                    autoplay: false,
                    perPage: 1,
                    perMove: 1,
                    gap: "1em",
                    padding: "0em",
                    pauseOnHover: false,
                    resetProgress: false,
                  },
                },
              }}
              muted={true}
            >
              <SplideSlide className="h-100">
                <div className="d-flex p-lg-4" style={{ gap: "2em" }}>
                  <div className="w-25 d-none d-lg-block">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/testimonial1.png"
                      className="rounded-0"
                    ></img>
                  </div>
                  <div className="position relative">
                    <div className="position-absolute inverted-comma d-lg-none">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/inverted.png"
                        className="img-fluid"
                      ></img>
                    </div>
                    <div className="ps-5 ps-lg-0">
                      <h4 className="poppins-medium text-uppercase mb-4">
                        „Einer der besten E-Bike-Hersteller auf dem Markt! Ich
                        habe immer wieder E-Bikes bekommen, die genau meinen
                        Wünschen entsprechen, und sie enttäuschen nie, 11/10
                        empfehlenswert!……“
                      </h4>
                      <p className="poppins-medium mb-0">James Mill</p>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="d-flex p-lg-4" style={{ gap: "2em" }}>
                  <div className="w-25 d-none d-lg-block">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/testimonial2.png"
                      className="rounded-0"
                    ></img>
                  </div>
                  <div className="position relative">
                    <div className="position-absolute inverted-comma d-lg-none">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/inverted.png"
                        className="img-fluid"
                      ></img>
                    </div>
                    <div className="ps-5 ps-lg-0">
                      <h4 className="poppins-medium text-uppercase mb-4">
                        „Der Wechsel zu Dynem war für uns ein Wendepunkt! Der
                        gesamte Prozess, von der Beschaffung bis zur Lieferung,
                        verlief reibungslos. Darüber hinaus konnten wir durch
                        die Verlagerung unserer Produktion nach Indien
                        erhebliche Einsparungen erzielen. Sehr zu empfehlen!"
                      </h4>
                      <p className="poppins-medium mb-0">Jake Severisson</p>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="d-flex p-lg-4" style={{ gap: "2em" }}>
                  <div className="w-25 d-none d-lg-block">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/testimonial3.png"
                      className="rounded-0"
                    ></img>
                  </div>
                  <div className="position relative">
                    <div className="position-absolute inverted-comma d-lg-none">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/inverted.png"
                        className="img-fluid"
                      ></img>
                    </div>
                    <div className="ps-5 ps-lg-0">
                      <h4 className="poppins-medium text-uppercase mb-4">
                        „Die Zusammenarbeit mit Dynem war einfach hervorragend.
                        Der Übergang von China nach Indien verlief unglaublich
                        reibungslos und die Kosteneinsparungen, die wir
                        erzielten, waren erheblich. Toller Partner für die
                        E-Bike-Herstellung!
                      </h4>
                      <p className="poppins-medium mb-0">Carolina Spike</p>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="d-flex p-lg-4" style={{ gap: "2em" }}>
                  <div className="w-25 d-none d-lg-block">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/testimonial4.png"
                      className="rounded-0"
                    ></img>
                  </div>
                  <div className="position relative">
                    <div className="position-absolute inverted-comma d-lg-none">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/inverted.png"
                        className="img-fluid"
                      ></img>
                    </div>
                    <div className="ps-5 ps-lg-0">
                      <h4 className="poppins-medium text-uppercase mb-4">
                        „Dynem hat uns den Wechsel zur indischen Fertigung
                        mühelos ermöglicht. Ihre Liebe zum Detail und ihr
                        Engagement für Qualität waren beeindruckend und die
                        Kostenvorteile waren sofort spürbar. Wir sind mit dem
                        Wechsel sehr zufrieden!“
                      </h4>
                      <p className="poppins-medium mb-0">Kevin Spearson</p>
                    </div>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </div>
        {/* news */}
        <div className="news">
          <div className="container mt-5 py-4 py-lg-5">
            <h2 className="poppins-medium mb-3 mb-lg-5">In den Nachrichten</h2>
            <Splide
              className="mb-2 mb-lg-0 p-0 align-items-center"
              options={{
                arrows: false,
                drag: false,
                type: "slide",
                pagination: false,
                autoplay: false,
                perMove: 1,
                gap: "2em",
                padding: "0em",
                pauseOnHover: true,
                resetProgress: false,
                perPage: 4,
                speed: 1000,
                interval: 3000,
                breakpoints: {
                  700: {
                    drag: true,
                    perPage: 1.3,
                    arrows: false,
                    perMove: 1,
                    gap: "1em",
                    padding: "0em",
                    pauseOnHover: false,
                    resetProgress: false,
                    focus: 0,
                    start: 0,
                    speed: 1000,
                    interval: 3000,
                  },
                },
              }}
              muted={true}
            >
              <SplideSlide className="h-100">
                <div className="p-3" style={{ backgroundColor: "white" }}>
                  <div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/News+1.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/kxan.png"
                    className="img-fluid mt-2"
                  ></img>
                  <p className="my-3 news-heading poppins-medium">
                    DYNEM beginnt mit dem Bau der weltweit größten integrierten
                    Elektrofahrrad-Gigafabrik.....
                  </p>
                  <small>May 09, 2024</small>
                  <div
                    className="w-100 text-center py-2 mt-2"
                    style={{
                      backgroundColor: "black",
                      minHeight: "max-content",
                    }}
                  >
                    <a
                      className="text-light text-decoration-none"
                      href="https://www.kxan.com/business/press-releases/cision/20240509IO08933/dynem-begins-construction-of-worlds-biggest-integrated-electric-cycle-gigafactory-in-india/"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-3" style={{ backgroundColor: "white" }}>
                  <div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/News+2.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ktla.png"
                    className="img-fluid mt-2"
                  ></img>
                  <p className="my-3 news-heading poppins-medium">
                    Indiens DYNEM errichtet die weltweit größte integrierte
                    E-Bike-Gigafabrik
                  </p>
                  <small>May 09, 2024</small>
                  <div
                    className="w-100 text-center py-2 mt-2"
                    style={{
                      backgroundColor: "black",
                      minHeight: "max-content",
                    }}
                  >
                    <a
                      className="text-light text-decoration-none"
                      href="https://fox59.com/business/press-releases/cision/20240509IO08933/dynem-begins-construction-of-worlds-biggest-integrated-electric-cycle-gigafactory-in-india/"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-3" style={{ backgroundColor: "white" }}>
                  <div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/News+3.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/benzinga.png"
                    className="img-fluid mt-2"
                  ></img>
                  <p className="my-3 news-heading poppins-medium">
                    DYNEM macht den ersten Spatenstich für die weltgrößte
                    integrierte Elektrofahrrad-Gigafabrik …
                  </p>
                  <small>May 09, 2024</small>
                  <div
                    className="w-100 text-center py-2 mt-2"
                    style={{
                      backgroundColor: "black",
                      minHeight: "max-content",
                    }}
                  >
                    <a
                      className="text-light text-decoration-none"
                      href="https://www.benzinga.com/pressreleases/24/05/n38730936/dynem-begins-construction-of-worlds-biggest-integrated-electric-cycle-gigafactory-in-india"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-3" style={{ backgroundColor: "white" }}>
                  <div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/News+4.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/fox8.png"
                    className="img-fluid mt-2"
                  ></img>
                  <p className="my-3 news-heading poppins-medium">
                    DYNEM stärkt Indiens Produktionskapazitäten: das größte der
                    Welt ....
                  </p>
                  <small>May 09, 2024</small>
                  <div
                    className="w-100 text-center py-2 mt-2"
                    style={{
                      backgroundColor: "black",
                      minHeight: "max-content",
                    }}
                  >
                    <a
                      className="text-light text-decoration-none"
                      href="https://www.benzinga.com/pressreleases/24/05/n38730936/dynem-begins-construction-of-worlds-biggest-integrated-electric-cycle-gigafactory-in-india"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </div>
        {/* FAQs */}
        <div className="container mt-5 pt-lg-5">
          <div className="d-flex faq-landing">
            <div className="faq-landing-1">
              <h2 className="poppins-medium mb-2 mb-lg-4">
                Häufig gestellte Fragen
              </h2>
              <p className="mb-lg-5">
                Haben Sie Fragen? Wir haben bereits Antworten. Es ist, als
                könnten wir Sie denken hören.
              </p>
              <div className="d-lg-block d-none">
                <h4 className="poppins-medium mb-2">
                  Haben wir etwas verpasst?
                </h4>
                <p className="mb-lg-5">
                  Vereinbaren Sie ein persönliches Gespräch mit unseren
                  Experten.
                </p>
                <div className="req-call my-5 my-lg-0">
                  <a
                    href="tel:+918956703515"
                    className="btn btn-dark px-5 d-flex justify-content-between rounded-0"
                    style={{ width: "fit-content" }}
                  >
                    Fordern Sie einen Anruf an
                  </a>
                </div>
              </div>
            </div>
            <div className="accordion faq-landing-2" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Was unterscheidet DYNEM von anderen E-Bike-Herstellern?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body poppins-thin">
                    <p>
                      DYNEM zeichnet sich durch innovative Innovationen,
                      umweltfreundliche Praktiken und umfangreiche
                      Anpassungsmöglichkeiten aus. Unsere hochmoderne
                      Gigafactory, globale Compliance und unser Expertenteam
                      sorgen für erstklassige Qualität und nahtlosen Support von
                      der Produktion bis zum After-Sales, sodass Sie sich rundum
                      beruhigt zurücklehnen können.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Kann ich mein E-Bike mit DYNEM individualisieren?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      Absolut! DYNEM bietet umfangreiche
                      Individualisierungsmöglichkeiten, sodass Sie Ihr E-Bike an
                      Ihren spezifischen Stil und Ihre Bedürfnisse anpassen
                      können. Mit unserem OEM-Service können Sie Branding,
                      Spezifikationen und Funktionen an Ihre Vorlieben anpassen.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Wie viele E-Bikes produziert DYNEM jährlich?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      DYNEM verfügt über die Kapazität, jährlich 500.000 E-Bikes
                      zu produzieren und anzupassen. Unsere effizienten
                      Produktionsprozesse und erstklassige Infrastruktur
                      ermöglichen es uns, der wachsenden globalen Nachfrage
                      gerecht zu werden.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFour">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    Welche Vorteile bieten die Antidumping-Lösungen von DYNEM?
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      Mit den legalen Antidumping-Lösungen von DYNEM können
                      Importeure bis zu 87 % Steuern sparen. Unsere Produkte
                      entsprechen den globalen Vorschriften und gewährleisten
                      gleiche Wettbewerbsbedingungen ohne Antidumpingsteuern.
                      Dies macht DYNEM zu einer kostengünstigen und konformen
                      Wahl für Importeure weltweit.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingfive">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    Wie unterstützt DYNEM Nachhaltigkeit?
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      DYNEM setzt sich für Nachhaltigkeit durch
                      umweltfreundliche Praktiken und innovative Technologie
                      ein. Unsere E-Bikes sind darauf ausgelegt, den
                      CO2-Fußabdruck zu reduzieren und eine grünere Zukunft zu
                      fördern, ganz im Einklang mit unserer Vision einer
                      nachhaltigen Mobilität.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingSix">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Welche Art von After-Sales-Support bietet DYNEM?
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingSix"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      DYNEM legt großen Wert auf die Kundenzufriedenheit mit
                      engagiertem After-Sales-Support. Vom ersten Kauf bis zur
                      langfristigen Wartung ist unser Team bestrebt, nahtlose
                      und zuverlässige Unterstützung zu leisten und unseren
                      Kunden ein reibungsloses und zufriedenstellendes Erlebnis
                      zu gewährleisten.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-lg-none text-center mt-3">
              <h4 className="poppins-medium mb-2">Haben wir etwas verpasst?</h4>
              <p className="mb-lg-5">
                Vereinbaren Sie ein persönliches Gespräch mit unseren Experten.
              </p>
              <div className="req-call my-4">
                <a
                  href="tel:+918956703515"
                  className="btn btn-dark px-5 d-flex justify-content-between rounded-0"
                  style={{ width: "fit-content" }}
                >
                  Fordern Sie einen Anruf an
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-block d-none">
          <div className="d-flex justify-content-center align-items-center mt-4 mt-lg-5">
            <Modal
              language={"german"}
              className="primary-button fw-bold px-4 py-2"
            />
          </div>
        </div>

        {/* contact us */}
        <div className="mt-5 py-lg-5" style={{ backgroundColor: "black" }}>
          <div className="container py-lg-5 py-4">
            <div className="d-flex mb-3 mb-lg-0" style={{ gap: "4em" }}>
              <div className="d-none d-lg-block w-50">
                <div className="row">
                  <div className="col-6 p-0">
                    <div className="d-flex justify-content-end">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid1.png"
                        className="w-75 p-3"
                      ></img>
                    </div>
                    <div className="d-flex justify-content-end">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid5.png"
                        className="w-100 p-3"
                      ></img>
                    </div>
                  </div>
                  <div className="col-6 p-0">
                    <div className="d-flex justify-content-start mt-5">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid2.png"
                        className="w-100 p-3"
                      ></img>
                    </div>
                    <div className="d-flex justify-content-start">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid4.png"
                        className="w-75 p-3"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-none d-lg-block">
                <h2 className="poppins-medium mb-4 text-light">
                  Lassen Sie uns verbinden
                </h2>
                <form className="contact-us-landing" onSubmit={submit}>
                  <div class="mb-3 d-flex" style={{ gap: "1em" }}>
                    <div className="w-50">
                      <label
                        for="exampleInputEmail1"
                        className="form-label text-light"
                      >
                        Vollständiger Name*
                      </label>
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        required
                        class="form-control rounded-0"
                        aria-describedby="emailHelp"
                      />
                    </div>
                    <div className="w-50">
                      <label
                        for="exampleInputEmail1"
                        className="form-label text-light"
                      >
                        Land*
                      </label>
                      <input
                        type="text"
                        value={country}
                        onChange={(e) => {
                          setCountry(e.target.value);
                        }}
                        required
                        class="form-control rounded-0"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>
                  <div class="mb-3 d-flex" style={{ gap: "1em" }}>
                    <div class="w-50">
                      <label
                        for="exampleInputPassword1"
                        class="form-label text-light"
                      >
                        Kontakt Nr*
                      </label>
                      <PhoneInput
                        value={phoneNumber}
                        onChange={setPhoneNumber}
                        required
                        defaultCountry="IN"
                        className="form-control w-100 rounded-0"
                      />
                    </div>
                    <div className="w-50">
                      <label
                        for="exampleInputEmail1"
                        className="form-label text-light"
                      >
                        Arbeits-E-Mail*
                      </label>
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        required
                        class="form-control rounded-0"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>

                  <div class="mb-4 d-flex" style={{ gap: "1em" }}>
                    <div className="w-50">
                      <label
                        for="exampleInputEmail1"
                        className="form-label text-light"
                      >
                        Organisation*
                      </label>
                      <input
                        type="text"
                        value={companyName}
                        required
                        onChange={(e) => {
                          setCompanyName(e.target.value);
                        }}
                        class="form-control rounded-0"
                        aria-describedby="emailHelp"
                      />
                    </div>
                    <div className="w-50">
                      <label
                        for="exampleInputEmail1"
                        className="form-label text-light"
                      >
                        Bezeichnung*
                      </label>
                      <input
                        type="text"
                        required
                        value={designation}
                        onChange={(e) => {
                          setDesignation(e.target.value);
                        }}
                        class="form-control rounded-0"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label
                      for="exampleInputEmail1"
                      className="form-label text-light"
                    >
                      Veröffentlichen Sie Ihre Anfrage*
                    </label>
                    <textarea
                      class="form-control rounded-0"
                      required
                      id="exampleFormControlTextarea1"
                      rows="3"
                      value={enquiry}
                      onChange={(e) => {
                        setEnquiry(e.target.value);
                      }}
                    ></textarea>
                  </div>
                  <button
                    type="submit"
                    class="btn btn-light w-100 poppins-medium rounded-0"
                  >
                    Holen Sie sich ein Angebot
                  </button>
                </form>
              </div>
            </div>
            <div className="d-lg-none mb-5">
              <h2 className="poppins-medium mb-4 text-light">
                Lassen Sie uns verbinden
              </h2>
              <form className="contact-us-landing" onSubmit={submit}>
                <div class="mb-3 d-flex" style={{ gap: "1em" }}>
                  <div className="w-50">
                    <label
                      for="exampleInputEmail1"
                      className="form-label text-light"
                    >
                      Vollständiger Name*
                    </label>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      required
                      class="form-control rounded-0"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div className="w-50">
                    <label
                      for="exampleInputEmail1"
                      className="form-label text-light"
                    >
                      Land*
                    </label>
                    <input
                      type="text"
                      value={country}
                      onChange={(e) => {
                        setCountry(e.target.value);
                      }}
                      required
                      class="form-control rounded-0"
                      aria-describedby="emailHelp"
                    />
                  </div>
                </div>
                <div class="mb-3 d-flex" style={{ gap: "1em" }}>
                  <div class="w-50">
                    <label
                      for="exampleInputPassword1"
                      class="form-label text-light"
                    >
                      Kontakt Nr*
                    </label>
                    <PhoneInput
                      value={phoneNumber}
                      onChange={setPhoneNumber}
                      required
                      defaultCountry="IN"
                      className="form-control w-100 rounded-0"
                    />
                  </div>
                  <div className="w-50">
                    <label
                      for="exampleInputEmail1"
                      className="form-label text-light"
                    >
                      Arbeits-E-Mail*
                    </label>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      required
                      class="form-control rounded-0"
                      aria-describedby="emailHelp"
                    />
                  </div>
                </div>

                <div class="mb-4 d-flex" style={{ gap: "1em" }}>
                  <div className="w-50">
                    <label
                      for="exampleInputEmail1"
                      className="form-label text-light"
                    >
                      Organisation*
                    </label>
                    <input
                      type="text"
                      value={companyName}
                      required
                      onChange={(e) => {
                        setCompanyName(e.target.value);
                      }}
                      class="form-control rounded-0"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div className="w-50">
                    <label
                      for="exampleInputEmail1"
                      className="form-label text-light"
                    >
                      Bezeichnung*
                    </label>
                    <input
                      type="text"
                      required
                      value={designation}
                      onChange={(e) => {
                        setDesignation(e.target.value);
                      }}
                      class="form-control rounded-0"
                      aria-describedby="emailHelp"
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label
                    for="exampleInputEmail1"
                    className="form-label text-light"
                  >
                    Veröffentlichen Sie Ihre Anfrage*
                  </label>
                  <textarea
                    class="form-control rounded-0"
                    id="exampleFormControlTextarea1"
                    required
                    rows="3"
                    value={enquiry}
                    onChange={(e) => {
                      setEnquiry(e.target.value);
                    }}
                  ></textarea>
                </div>
                <button
                  type="submit"
                  class="btn btn-light w-100 poppins-medium rounded-0"
                >
                  Holen Sie sich ein Angebot
                </button>
              </form>
            </div>
            <div className="row p-2 d-lg-none">
              <div className="col-6 p-0">
                <div className="d-flex justify-content-end">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid1.png"
                    className="w-75 p-2"
                  ></img>
                </div>
                <div className="d-flex justify-content-end">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid5.png"
                    className="w-100 p-2"
                  ></img>
                </div>
              </div>
              <div className="col-6 p-0">
                <div className="d-flex justify-content-start mt-5">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid2.png"
                    className="w-100 p-2"
                  ></img>
                </div>
                <div className="d-flex justify-content-start">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid4.png"
                    className="w-75 p-2"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetQuoteGerman;
