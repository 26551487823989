import React, { useEffect, useState } from "react";
import { GraphQLClient } from "graphql-request";
import { gql } from "graphql-request";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import Navbar from "../components/Navbar";
import Meta from "../components/Meta";
import Footer from "../components/Footer";
import "../css/blogs.css";

const Blogs = () => {
  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [blogPosts, setBlogPosts] = useState([]);
  const [bannerImage, setBannerImage] = useState("");
  const [mainHeading, setMainHeading] = useState("");
  const [mainExcerpt, setMainExcerpt] = useState("");
  const hygraph = new GraphQLClient(
    "https://ap-south-1.cdn.hygraph.com/content/clyn0n8l500x208vystxkfz7b/master"
  );

  const QUERY = gql`
    {
      posts(orderBy: date_DESC) {
        id
        title
        slug
        date
        excerpt
        coverImage {
          url
        }
        content {
          html
        }
      }
    }
  `;
  useEffect(() => {
    const getBlogs = async () => {
      const { posts } = await hygraph.request(QUERY);
      setBlogPosts(posts);
      setBannerImage(posts[0].coverImage.url);
      setMainExcerpt(posts[0].excerpt);
      setMainHeading(posts[0].title);
    };
    getBlogs();
  }, []);

  return (
    <>
      <Meta />
      <Navbar />
      <div>
        {blogPosts && (
          <>
            <div className="p-5 d-none d-lg-block">
              <div
                className="d-flex justify-content-center"
                style={{ gap: "2em" }}
              >
                <div className="mx-auto">
                  <picture className="mx-auto">
                    <source media="(max-width:650px)" srcSet={bannerImage} />
                    <source media="(min-width:651px)" srcSet={bannerImage} />
                    <img src={bannerImage} alt="Banner1" className="w-100" />
                  </picture>
                </div>

                <div className="d-flex align-items-center">
                  <div>
                    <h1 className="mb-3" style={{ fontSize: "3em" }}>
                      {mainHeading}
                    </h1>
                    <p className="mb-1">{mainExcerpt}</p>
                    <Link
                      to={`/blogs/${blogPosts[0]?.slug}`}
                      className="text-decoration-none text-danger"
                    >
                      Read More...
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="p-3 pb-0 d-lg-none">
              <img src={bannerImage} alt="Banner1" className="w-100 mb-4" />

              <div>
                <h1 className="" style={{ fontSize: "1em" }}>
                  {mainHeading}
                </h1>
                <p style={{ fontSize: "0.8em" }} className="mb-1">
                  {mainExcerpt}
                </p>
                <Link
                  to={`/blogs/${blogPosts[0]?.slug}`}
                  className="text-decoration-none text-danger"
                >
                  Read More...
                </Link>
              </div>
            </div> */}
            <div className="container mt-lg-5 mt-4">
              <h2 className="pb-lg-3 fw-bold  mt-2 pt-lg-2">Latest Articles</h2>
              {/* desktop cards */}
              <div className="">
                <div className="row">
                  {blogPosts.map((post, i) => (
                    <div key={i} className="col-12 col-lg-4 mb-4">
                      <Link
                        to={`/blogs/${post.slug}`}
                        className="text-decoration-none text-dark"
                      >
                        <div
                          className=" p-2 h-100"
                          style={{ boxShadow: "1px 1px 5px lightgrey" }}
                        >
                          <div>
                            <img
                              src={post?.coverImage?.url}
                              alt=""
                              className="img-fluid rounded-2"
                            />
                          </div>
                          <p
                            style={{ fontSize: "0.9em" }}
                            className="text-muted mt-3 mb-2 "
                          >
                            <span className="pe-2 text-muted">
                              {weekday[new Date(post.date).getDay()]},
                            </span>
                            <span className="pe-2 text-muted">
                              {new Date(post.date).getDate()} {""}
                              {months[new Date(post.date).getMonth()]}
                            </span>
                            <span className="pe-2 text-muted">
                              {new Date(post.date).getFullYear()}
                            </span>
                          </p>
                          <h6
                            className="fw-bold "
                            style={{
                              display: "-webkit-box",
                              minHeight: "50px",
                              maxWidth: "100%",
                              WebkitLineClamp: "2",
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                            }}
                          >
                            {post.title}
                          </h6>
                          <p
                            className="text-muted mt-2"
                            style={{
                              display: "-webkit-box",
                              fontSize: "0.9em",
                              maxWidth: "100%",
                              WebkitLineClamp: "2",
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              minHeight: "40px",
                            }}
                          >
                            {post.excerpt}
                          </p>

                          <Link
                            to={`/blogs/${post.slug}`}
                            style={{}}
                            className="dynem-bg-color w-100 text-decoration-none d-block text-center border-0 rounded-3 text-light p-2 w-100"
                          >
                            Read More{" "}
                            <Icon icon="ph:arrow-right-light" width={30} />
                          </Link>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>

              {/* mobile-cards */}

              {/* <div className="blogs-card d-lg-none container mt-0  pb-lg-5 mt-lg-3 p-3  pe-0 mb-2 ps-2">
            <Swiper
              spaceBetween={10}
              slidesPerView={1.2}
              breakpoints={{
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
              }}
              slide
            >
              {blogPosts &&
                blogPosts.map(
                  (post, i) =>
                    i < 10 && (
                      <SwiperSlide className="p-1 rounded-3">
                        <div
                          className=" rounded-3 p-2 "
                          style={{ boxShadow: "1px 1px 5px lightgrey" }}
                        >
                          <Link
                            to={`/blogs/${post.slug}`}
                            className="text-decoration-none text-dark"
                          >
                            <div>
                              <img
                                src={post?.coverImage?.url}
                                alt=""
                                className="img-fluid rounded-2"
                              />
                            </div>
                            <p
                              style={{ fontSize: "0.8em" }}
                              className="text-muted mb-2 mt-2"
                            >
                              <span className="pe-2 text-muted">
                                {weekday[new Date(post.date).getDay()]},
                              </span>

                              <span className="pe-2 text-muted">
                                {new Date(post.date).getDate()} {""}
                                {months[new Date(post.date).getMonth()]}
                              </span>
                              <span className="pe-2 text-muted">
                                {new Date(post.date).getFullYear()}
                              </span>
                            </p>
                            <h6
                              className="fw-bold"
                              style={{
                                display: "-webkit-box",
                                maxWidth: "100%",
                                WebkitLineClamp: "2",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                minHeight: "60px",
                              }}
                            >
                              {post.title}
                            </h6>
                            <p
                              className="text-muted"
                              style={{
                                display: "-webkit-box",
                                maxWidth: "100%",
                                WebkitLineClamp: "4",
                                fontSize: "0.75em",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                minHeight: "70px",
                              }}
                            >
                              {post.excerpt}
                            </p>
                            <Link
                              to={`/blogs/${post.slug}`}
                              style={{ fontSize: "0.8em" }}
                              className="background-em w-100 d-block text-decoration-none text-center rounded-3 text-light p-2 p-lg-2 border-0 w-100"
                            >
                              Read More{" "}
                              <Icon icon="ph:arrow-right-light" width={20} />
                            </Link>
                          </Link>
                        </div>
                      </SwiperSlide>
                    )
                )}
            </Swiper>

            <Swiper
              className="mt-4"
              spaceBetween={10}
              slidesPerView={1.2}
              breakpoints={{
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
              }}
              slide
            >
              {blogPosts &&
                blogPosts.map(
                  (post, i) =>
                    i > 10 && (
                      <SwiperSlide className="p-1 rounded-3">
                        <div
                          className=" rounded-3 p-2 "
                          style={{ boxShadow: "1px 1px 5px lightgrey" }}
                        >
                          <Link
                            to={`/blogs/${post.slug}`}
                            className="text-decoration-none text-dark"
                          >
                            <div>
                              <img
                                src={post?.coverImage?.url}
                                alt=""
                                className="img-fluid rounded-2"
                              />
                            </div>
                            <p
                              style={{ fontSize: "0.8em" }}
                              className="text-muted mb-2 mt-2"
                            >
                              <span className="pe-2 text-muted">
                                {weekday[new Date(post.date).getDay()]},
                              </span>

                              <span className="pe-2 text-muted">
                                {new Date(post.date).getDate()} {""}
                                {months[new Date(post.date).getMonth()]}
                              </span>
                              <span className="pe-2 text-muted">
                                {new Date(post.date).getFullYear()}
                              </span>
                            </p>
                            <h6
                              className="fw-bold"
                              style={{
                                display: "-webkit-box",
                                maxWidth: "100%",
                                WebkitLineClamp: "2",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                minHeight: "60px",
                              }}
                            >
                              {post.title}
                            </h6>
                            <p
                              className="text-muted"
                              style={{
                                display: "-webkit-box",
                                maxWidth: "100%",
                                WebkitLineClamp: "4",
                                fontSize: "0.75em",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                minHeight: "70px",
                              }}
                            >
                              {post.excerpt}
                            </p>
                            <Link
                              to={`/blogs/${post.slug}`}
                              style={{ fontSize: "0.8em" }}
                              className="background-em text-decoration-none d-block text-center w-100 rounded-3 text-light p-2 p-lg-2 border-0 w-100"
                            >
                              Read More{" "}
                              <Icon icon="ph:arrow-right-light" width={20} />
                            </Link>
                          </Link>
                        </div>
                      </SwiperSlide>
                    )
                )}
            </Swiper>
          </div> */}
            </div>
          </>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Blogs;
